import { getTheme, createTheme } from "@fluentui/react";
import { ThemeProvider, Theme } from "@fluentui/react-theme-provider"
import { HubConnection } from "@microsoft/signalr";
import React, { createContext, ReactNode, useState } from "react";
import { AuthContextProvider } from "./AuthContext";

const theme = getTheme();
type BradingType = {
    companyName: string,
    logo: string,
    backgroundColor: string,
    fontColor: string,
    icon: string,
    imagotype: string,
    appTagline: string,
    appName: string,
    appLogo: string,
    appiconLogo: string,
    theme: Theme,
    helpPath: string,
    createExampleApplication: boolean,
    createExampleRecord: boolean,
    exampleUniqueReferenceToImport: string,
    showGetStarted: boolean,
    showDefaultExamplesSection: boolean,
    showCustomExamplesLink: boolean,
    customExamplesLink: string,
    customExamplesLinkTarget: string,
    showWebformUseCasesLink: boolean,
    webformUseCasesLink: string,
    webformUseCasesLinkTarget: string,
    showFAQLink: boolean,
    faqLink: string,
    faqLinkTarget: string,
    supportEmail?: string
    shareEmail?: string
}
export type ProfileType = {
    Email: string
    FirstName: string,
    MiddleName: string,
    LastName: string
    Language: string
    Billing: boolean
    AdminBilling: boolean
}
type AppContextType = {
    branding: BradingType,
    setBranding: React.Dispatch<React.SetStateAction<BradingType>>,
    connection: HubConnection,
    setConnection: React.Dispatch<React.SetStateAction<HubConnection>>,
    kimDocumentCreateSuccessMessage: string,
    setKimDocumentCreateSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
    kimDocumentCreateFailedMessage: string,
    setKimDocumentCreateFailedMessage: React.Dispatch<React.SetStateAction<string>>,
    kimDocumentUpdateSuccessMessage: string,
    setKimDocumentUpdateSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
    kimDocumentUpdateFailedMessage: string,
    setKimDocumentUpdateFailedMessage: React.Dispatch<React.SetStateAction<string>>,
    categoryFocus: string | undefined,
    setCategoryFocus: React.Dispatch<React.SetStateAction<string | undefined>>,
    profile: ProfileType | undefined,
    setProfile: React.Dispatch<React.SetStateAction<ProfileType | undefined>>

}
export const customAppContext = createContext({} as AppContextType)

export const defaultTheme: Theme = createTheme({
    palette: {
        themePrimary: '#3e7bbb',
        themeLighterAlt: '#f5f9fc',
        themeLighter: '#dae6f4',
        themeLight: '#bbd2ea',
        themeTertiary: '#80a9d6',
        themeSecondary: '#5087c2',
        themeDarkAlt: '#376ea8',
        themeDark: '#2f5d8d',
        themeDarker: '#224468',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralSecondaryAlt: '#8a8886',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
})


const AppProvider = ({ children }: { children: ReactNode }) => {
    const [connection, setConnection] = useState<any>(null);
    const [kimDocumentCreateSuccessMessage, setKimDocumentCreateSuccessMessage] = useState("")
    const [kimDocumentCreateFailedMessage, setKimDocumentCreateFailedMessage] = useState("")
    const [kimDocumentUpdateSuccessMessage, setKimDocumentUpdateSuccessMessage] = useState("")
    const [kimDocumentUpdateFailedMessage, setKimDocumentUpdateFailedMessage] = useState("")
    const [categoryFocus, setCategoryFocus] = useState<string | undefined>(undefined)
    const [profile, setProfile] = useState<ProfileType | undefined>(undefined)
    const [branding, setBranding] = useState<BradingType>({
        backgroundColor: theme.palette.themePrimary,
        fontColor: '#fff',
        icon: "/icon.ico",
        imagotype: "imagotype.png",
        appTagline: "The form, document and records management office productivity tool",
        companyName: '...',
        appiconLogo: '',
        appLogo: '',
        appName: '',
        logo: '',
        theme: defaultTheme,
        helpPath: '',
        createExampleApplication: true,
        createExampleRecord: true,
        showGetStarted: true,
        showDefaultExamplesSection: true,
        exampleUniqueReferenceToImport: "",
        showCustomExamplesLink: false,
        customExamplesLink: "#",
        customExamplesLinkTarget: "#",
        showWebformUseCasesLink: false,
        webformUseCasesLink: "#",
        webformUseCasesLinkTarget: "#",
        showFAQLink: false,
        faqLink: "#",
        faqLinkTarget: "#",
    } as BradingType)

    return (
        <ThemeProvider theme={branding.theme}>
            <AuthContextProvider>
                <customAppContext.Provider value={{
                    branding,
                    setBranding,
                    connection,
                    setConnection,
                    kimDocumentCreateSuccessMessage,
                    setKimDocumentCreateSuccessMessage,
                    kimDocumentCreateFailedMessage,
                    setKimDocumentCreateFailedMessage,
                    kimDocumentUpdateSuccessMessage,
                    setKimDocumentUpdateSuccessMessage,
                    kimDocumentUpdateFailedMessage,
                    setKimDocumentUpdateFailedMessage,
                    categoryFocus,
                    setCategoryFocus,
                    profile,
                    setProfile
                }}>
                    {children}
                </customAppContext.Provider>
            </AuthContextProvider>
        </ThemeProvider>
    )

}
export default AppProvider