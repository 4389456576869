import React, {useEffect, useState} from 'react';

import '../../Custom.css'
import {confirmDialog, contentStyles, iconButtonStyles} from './style'

import {Modal} from '@fluentui/react/lib/Modal';
import {Separator} from '@fluentui/react/lib/Separator';
import {Dialog, DialogFooter, DialogType} from '@fluentui/react/lib/Dialog';
import {ProgressIndicator} from '@fluentui/react/lib/ProgressIndicator';

import {Icon, Link, MessageBar, MessageBarType, TextField, Stack, Text} from '@fluentui/react';

import {useTranslation, Trans} from 'react-i18next'
import {useForm} from "react-hook-form";
import HelpComponentRelative from '../Help/HelpComponentRelative'

import {DefaultButton, IconButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {IIconProps} from '@fluentui/react/lib/Icon';
import Helper from "../../Helpers/Helper";
import {Buffer} from 'buffer';
import {IApplicationImport} from "../ImportTemplate/ImportTemplate";
import {saveAs} from "file-saver";
import {useBranding} from "../../hooks/useBranding";
import {emptyTemplate, parseTemplate} from "../ImportTemplate";
import AudienceService from "../../services/audience";

export interface IApplicationV1 {
    FileName: string;
    Id: number;
    Title: string;
    Description: string;
    Pages: string;
    Revision: string;
    Author: string;
    Keywords: string;
    Created: string;
    LastSave: string,
    Exported: string;
    Lists: string,
    Expressions: string
    Template: string;
    Protected: boolean;
    AppName: string;
    Version: string;
}

/**
 * Component ImportTemplate render
 * @param props
 */

function ImportExternalPackage(props: any) {
    const {branding} = useBranding()

    const emptyApplicationJSON: IApplicationV1 = {
        AppName: "",
        Author: "",
        Created: "",
        Protected: false,
        Exported: "",
        Description: "",
        FileName: "",
        Keywords: "",
        LastSave: "",
        Lists: "",
        Expressions: "",
        Pages: "",
        Template: "",
        Title: "",
        Version: "",
        Revision: "",
        Id: -1
    }

    const [applicationFileObject, setApplicationFileObject] = useState(emptyApplicationJSON);
    const [applicationFileObjectTemplate, setApplicationFileObjectTemplate] = useState(emptyTemplate);
    const [isHiddenConfirmDialog, setIsHiddenConfirmDialog] = useState(true)
    const [currentDownloadList, setCurrentDownloadList] = useState("")
    const [currentDownloadListName, setCurrentDownloadListName] = useState("")
    const [ImportedItems, setImportedItems] = useState<IApplicationImport[]>([])
    const [isNotApplicationAllowed, setIsNotApplicationAllowed] = useState(true)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState("")
    const [ErrorMessage, setErrorMessage] = useState("")
    const [fileName, setFileName] = useState("")
    const [hiddenPasswordDialog, setHiddenPasswordDialog] = useState(true)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [passwordField, setPasswordField] = useState("")
    const [isURLInvalid, setIsURLInvalid] = useState(true)
    const [isFormInvalid, setIsFormInvalid] = useState(true)
    const [isImporting, setIsImporting] = useState(false)
    const [isAsyncProcess, setIsAsyncProcess] = useState(false)
    const [isImportingExamples, setIsImportingExamples] = useState(false)

    const {reset, formState: {}} = useForm();
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const {t} = useTranslation(['common', 'ImportExternalComponent']);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: confirmDialog,
            dragOptions: undefined,
        }),
        [],
    );

    const dialogPasswordProps = {
        type: DialogType.normal,
        title: t("ImportExternalComponent.PasswordDialog title", {ns: 'importExternalComponent'}),
        closeButtonAriaLabel: t("Common.Button.Cancel", {ns: 'common'}),
        subText: undefined
    };

    const dialogConfirmProps = {
        type: DialogType.normal,
        title: t("ImportExternalComponent.ConfirmDialog title", {ns: 'importExternalComponent'}),
        closeButtonAriaLabel: t("Common.Button.Cancel", {ns: 'common'}),
        subText: t("ImportExternalComponent.ConfirmDialog subText", {ns: 'importExternalComponent'})
    };

    /**
     * Dismiss the PasswordDialog
     */
    const dismissPasswordDialog = () => {
        reset()
        setPasswordField("");
        setHiddenPasswordDialog(true)
    }

    /**
     * Function to hide and clear form fields of the current modal
     * */
    const hideModal = () => {
        sessionStorage.removeItem("importExternalPackage")
        sessionStorage.removeItem("importExamples")
        setShowSuccessModal(false)
        setSuccessMessage("")
        setErrorMessage("")
        setShowSuccessMessage(false)
        setShowErrorMessage(false)
        setIsImporting(false)
        setIsFormInvalid(true)
        setIsURLInvalid(true)
        setIsImportingExamples(false)
        setFileName("")
        setApplicationFileObject(emptyApplicationJSON)
        setApplicationFileObjectTemplate(emptyTemplate)
        setPasswordField("");
        setHiddenPasswordDialog(true)
        setIsHiddenConfirmDialog(true)
        setCurrentDownloadList("")
        setCurrentDownloadListName("")
        setIsAsyncProcess(false)
        props.hideImportExternalpackage()
    }

    /**
     * Function to read the File stream. Check that is a valid Application exported file
     * @param fileContent
     */
    const getBase64 = (fileContent: string) => {
        let applicationObject: IApplicationV1 = {
            AppName: "",
            Author: "",
            Created: "",
            Protected: false,
            Exported: "",
            Description: "",
            FileName: "",
            Keywords: "",
            LastSave: "",
            Lists: "",
            Expressions: "",
            Pages: "",
            Template: "",
            Title: "",
            Version: "",
            Revision: "",
            Id: -1
        }

        try {
            fileContent = Buffer.from(fileContent, 'base64').toString('utf8')
            applicationObject = JSON.parse(fileContent)

            let Validator = require('jsonschema').Validator;
            let v = new Validator();

            let applicationSchema = {
                "id": "/ApplicationV1",
                "type": "object",
                "properties": {

                    "FileName": {"type": "string"},
                    "Id": {"type": "number"},
                    "Title": {"type": "string"},
                    "Description": {"type": "string"},
                    "Pages": {"type": "string"},
                    "Revision": {"type": "string"},
                    "Author": {"type": "string"},
                    "Keywords": {"type": "string"},
                    "Created": {"type": "string"},
                    "LastSave": {"type": "string"},
                    "Exported": {"type": "string"},
                    "Lists": {"type": "string"},
                    "Expressions": {"type": "string"},
                    "Template": {"type": "string"},
                    "Protected": {"type": "boolean"},
                    "AppName": {"type": "string"},
                    "Version": {"type": "string"}
                },
                "required": ["FileName", "Id", "Title", "Description", "Pages", "Revision", "Author", "Keywords", "Exported", "Template", "Protected", "AppName", "Version"]
            };
            let resultValidation = v.validate(applicationObject, applicationSchema, {nestedErrors: true})

            if (resultValidation.valid) {
                setApplicationFileObject(applicationObject)
                setApplicationFileObjectTemplate(parseTemplate(applicationObject.Template));
                setSuccessMessage(t("ImportExternalComponent.Messages.ValidApplicationJSON", {
                    ns: 'importExternalComponent'
                }))
                setShowSuccessMessage(true)
                setIsURLInvalid(false)
                setIsFormInvalid(false)
            } else {
                setErrorMessage(t("ImportExternalComponent.Messages.InvalidApplicationJSON", {
                    ns: 'importExternalComponent',
                    Errors: resultValidation.errors.toString()
                }))
                setShowErrorMessage(true)
                setIsURLInvalid(true)
            }

        } catch (errors) {
            console.log(errors)
            setErrorMessage(t('ImportExternalComponent.Messages.InvalidApplicationFormat', {ns: 'importExternalComponent'}))
            setShowErrorMessage(true)
            setIsURLInvalid(true)
        }
    }

    /**
     * Function to Import the Selected Application
     */

    const importApplication = async () => {

        setIsImporting(true)
        setErrorMessage("")
        setShowErrorMessage(false)
        setSuccessMessage("")
        setShowSuccessMessage(false)

        let isJson: boolean = false;

        let clientDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'

            }
        ).format(new Date())

        await Helper.postImportTemplate(passwordField, applicationFileObject, clientDate)
            .then((data) => {

                if (data.success) {

                    try {
                        setImportedItems(JSON.parse(JSON.parse(data.result)));
                    } catch {
                        setImportedItems([])
                    }

                    hideModal()
                    setSuccessMessage(t("ImportExternalComponent.Messages.ImportSuccessful", {
                        ns: 'importExternalComponent',
                        applicationName: fileName
                    }))

                    const site = Helper.getSite()!


                    if(data.bag === undefined || data.bag !== "email") {
                        setShowSuccessMessage(true)
                        props.reloadApp(site, applicationFileObjectTemplate.Category)
                    }
                    else {
                        setIsAsyncProcess(true)
                    }
                    setShowSuccessModal(true)
                }

            })
            .catch(errors => {
                setErrorMessage(errors)
                setShowErrorMessage(true)
            })
            .finally(() => {
                    sessionStorage.removeItem("importExternalPackage")
                    sessionStorage.removeItem("importExamples")

                    setIsImporting(false)
                }
            )

    }

    /**
     * Function to show the confirm Download dialog
     * @param list in CSV format
     * @param name of the list
     */
    const showConfirmDownloadDialog = (list: string, name: string): void => {
        setIsHiddenConfirmDialog(false)
        setCurrentDownloadList(list)
        setCurrentDownloadListName(name)
    }

    /**
     * Function to download the List
     */
    const downloadList = async () => {

        let blob = new Blob([currentDownloadList], {
            type: "text/plain;charset=utf-8"
        });
        let name = currentDownloadListName.replace(/([^a-z0-9()_\-+ ]+)/gi, '-');
        saveAs(blob, `${name}.csv`);
    }

    /**
     * OnClick Import Button
     * */
    const importOnCLick = async () => {
        await loadSubscriptionTemplate().then((result)=> {
            if (result) {
                if (applicationFileObject.Protected) {
                    setPasswordField("")
                    setHiddenPasswordDialog(false)
                } else {
                    importApplication().then()
                }
            }
        })        
    }

    /**
     * Verify the MaxApplicationAllowed value
     */
    const loadSubscriptionTemplate = async () => {
        let result: boolean = false
        setIsNotApplicationAllowed(true)
        setIsImporting(true)
        await AudienceService.GetTemplateUsage()
            .then((data) => {
                if (data.Success) {
                    result = true
                    setIsNotApplicationAllowed(false)
                } else {
                    setErrorMessage(data.ExceptionMessage)
                    setShowErrorMessage(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setShowErrorMessage(true)
            })
            .finally(() => {
                setIsImporting(false)
            })

        return result
    }

    useEffect(() => {

        if (props.showImportTemplate) {

            loadSubscriptionTemplate().then(data => {
                if (data) {
                    const abortController = new AbortController()

                    const importApplication = sessionStorage.getItem("importExternalPackage")
                    const importExamples: string | null = sessionStorage.getItem("importExamples")
                    if (importExamples === null) {

                        let application = [""]
                        if (importApplication != null) {
                            application = importApplication!.split('_');
                        }

                        if (importApplication === null && application.length != 2) {
                            setIsFormInvalid(true)
                            setIsURLInvalid(true)

                            setErrorMessage(t('ImportExternalComponent.Messages.InvalidURLFormat', {
                                ns: 'importExternalComponent',
                                Errors: importApplication
                            }))
                            setShowErrorMessage(true)
                            setIsImporting(false)

                        } else {

                            let isJson: boolean = false;
                            (async () => {

                                    Helper.getImportGUIDTemplate(application[0], application[1], abortController)
                                        .then((data) => {
                                            getBase64(data.result)
                                        })
                                        .catch(error => {
                                            setSuccessMessage("")
                                            setShowSuccessMessage(false)
                                            setErrorMessage(Helper.GetErrorMessage(error))
                                            setShowErrorMessage(true)
                                        })
                                        .finally(() => {
                                                setIsImporting(false)
                                            }
                                        )
                                }
                            )().catch((errors) => {
                                console.log(errors)
                                setErrorMessage(t('ImportExternalComponent.Messages.InvalidReadFile', {
                                    ns: 'importExternalComponent',
                                    Errors: importApplication
                                }))
                                setShowErrorMessage(true)
                            })
                                .finally(() => {

                                    setIsImporting(false)
                                })

                        }
                    } else {
                        setIsImportingExamples(true)
                        if (importExamples.trim().length === 0) {
                            setIsFormInvalid(true)

                            setErrorMessage(t('ImportExternalComponent.Messages.InvalidReadFile', {
                                ns: 'importExternalComponent',
                                Errors: importApplication
                            }))
                            setShowErrorMessage(true)
                        } else {
                            getBase64(importExamples!.toString())

                        }
                        setIsImporting(false)
                    }

                    return () => {
                        abortController.abort()
                    }
                }
            })
        }
    }, [props.showImportTemplate])

    return (
        /*Modal*/
        <div>


            <Dialog
                hidden={isHiddenConfirmDialog}
                dialogContentProps={dialogConfirmProps}
                modalProps={modalProps}
                onDismiss={() => {
                    setIsHiddenConfirmDialog(true)
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setIsHiddenConfirmDialog(true);
                        downloadList().then(r => r)
                    }} text={t("Common.Button.Download", {ns: 'common'})}/>
                    <DefaultButton onClick={() => {
                        setIsHiddenConfirmDialog(true)
                    }} text={t("Common.Button.Cancel", {ns: 'common'})}/>
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hiddenPasswordDialog}
                onDismiss={dismissPasswordDialog}
                dialogContentProps={dialogPasswordProps}
                modalProps={modalProps}
            >
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <form>
                            <TextField
                                label={t("ImportExternalComponent.TextField Password", {ns: 'importExternalComponent'})}
                                type="password"
                                name="passwordField"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && passwordField.length > 0) {
                                        e.preventDefault();
                                        setHiddenPasswordDialog(true);
                                        importApplication().then()
                                    }
                                    if (e.key === "Enter" && passwordField.length === 0) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(_, v) => {
                                    setPasswordField(v!);
                                }}
                            />
                        </form>
                    </div>
                </div>

                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setHiddenPasswordDialog(true);
                        importApplication().then()
                    }} text={t("Common.Button.Continue", {ns: 'common'})}/>
                    <DefaultButton onClick={dismissPasswordDialog} text={t("Common.Button.Cancel", {ns: 'common'})}/>
                </DialogFooter>
            </Dialog>

            <Modal
                isOpen={showSuccessModal}
                containerClassName={contentStyles(branding.theme).container}
                isBlocking={true}>
                <form>
                    {/*Header*/}
                    <div className={contentStyles(branding.theme).header}>
                        <span>{isImportingExamples ? t("ImportExternalComponent.Component Examples", {ns: 'importExternalComponent'}) : t("ImportExternalComponent.Component Title", {ns: 'importExternalComponent'})}</span>
                    </div>
                    {/*End Header*/}

                    <Separator/>


                    <div className={contentStyles(branding.theme).body}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">

                                {showSuccessMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={true}
                                    >
                                        {SuccessMessage}
                                    </MessageBar>
                                }
                            </div>
                            <br/>
                            {/*First row*/}
                            <div className="ms-Grid-row">
                                {/*First column*/}
                                <div className="ms-Grid-col scrollVisible"
                                     style={{maxHeight: '40vh', overflow: "auto", overflowX: "hidden"}}>

                                    {isAsyncProcess ?
                                        <Stack horizontal>

                                            <Stack styles={{root: {alignItems: "center", padding: "0px 16px"}}}>
                                                <Icon iconName="ReminderTime"
                                                      styles={{root: {width: 32, height: 32, fontSize: "32px"}}}/>
                                            </Stack>
                                            <Stack grow={1}>
                                                <Text variant={"large"} block>
                                                    {t("ImportComponent.AsyncProcessTitle", {ns: 'importComponent'})}
                                                </Text>
                                                <Text variant={"medium"} block
                                                      styles={{root: {paddingTop: 8, whiteSpace: "pre-line"}}}>
                                                    <Trans>
                                                        {t("ImportComponent.AsyncProcessDescription", {ns: 'importComponent'})}
                                                    </Trans>
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        :
                                        <>
                                            <ul className={contentStyles(branding.theme).importedList}>

                                                {ImportedItems.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Icon iconName={item.Status ? 'SkypeCircleCheck' : 'Info'}
                                                                      style={{
                                                                          color: item.Status ? 'green' : 'orange',
                                                                          marginRight: 8
                                                                      }}/>
                                                                {item.Message}
                                                                <span>{item.Description}</span>
                                                                {!item.Status && item.Bag != null && item.Bag.trim().length > 0 &&
                                                                    <Link onClick={() => {
                                                                        showConfirmDownloadDialog(item.Bag, item.Message)
                                                                    }} underline>
                                                                        &nbsp;Download list
                                                                    </Link>
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </ul>
                                        </>
                                    }
                                </div>
                                {/*End First column*/}
                            </div>
                        </div>
                    </div>

                    <Separator/>

                    {/*Footer*/}
                    <div className={contentStyles(branding.theme).footer}>
                        <DialogFooter>
                            <PrimaryButton onClick={async () => {
                                hideModal()
                                if(!isAsyncProcess) props.notificartions(`The Application was imported successfully!`, "success");
                            }} text={t("Common.Button.Accept", {ns: 'common'})}
                                           title={t("Common.Button.Accept", {ns: 'common'})}/>
                        </DialogFooter>
                    </div>
                    {/*End Footer*/}
                </form>
            </Modal>

            <Modal
                isOpen={props.showImportTemplate}
                containerClassName={contentStyles(branding.theme).container}
                isBlocking={true}>
                <form>
                    {/*Header*/}
                    <div className={contentStyles(branding.theme).header}>
                        <span>{t("ImportExternalComponent.Component Title", {ns: 'importExternalComponent'})}</span>
                        <HelpComponentRelative
                            headline={t("ImportExternalComponent.Component Help headline", {ns: 'importExternalComponent'})}
                            text={t("ImportExternalComponent.Component Help Text", {ns: 'importExternalComponent'})}
                            right="50px"
                            top="15px"
                            location="importexport"
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            disabled={isImporting}
                            ariaLabel={t("Common.Close", {ns: 'common'})}
                            onClick={hideModal}
                        />
                    </div>
                    {/*End Header*/}

                    <Separator/>

                    {/*Body*/}
                    <div className={contentStyles(branding.theme).body}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                {showErrorMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {ErrorMessage}
                                    </MessageBar>
                                }
                                {showSuccessMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {SuccessMessage}
                                    </MessageBar>
                                }
                            </div>
                            <br/>
                            {/*First row*/}
                            <div className="ms-Grid-row">
                                {/*First column*/}
                                <div className="ms-Grid-col">
                                    <p>
                                        {t("ImportExternalComponent.Import Description", {ns: 'importExternalComponent'})}
                                    </p>
                                    <p>
                                        {/* <em>{t("ImportExternalComponent.Import Password", {ns: 'importExternalComponent'})}</em>*/}
                                    </p>
                                </div>
                                {/*End First column*/}
                            </div>
                            {/*End First row*/}
                            <br/>
                            {/*Details row*/}
                            {!isURLInvalid &&
                                <>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                            <label>
                                                <strong>{t("ImportExternalComponent.Label Category", {ns: 'importExternalComponent'})}</strong>
                                            </label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                            {applicationFileObject ? applicationFileObjectTemplate.Category : ""}
                                            <Separator/>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                            <label>
                                                <strong>{t("ImportExternalComponent.Label Application name", {ns: 'importExternalComponent'})}</strong>
                                            </label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                            {applicationFileObject ? applicationFileObjectTemplate.DisplayName : ""}
                                            <Separator/>
                                        </div>
                                    </div>

                                    {!showDetails &&
                                        <div className="ms-Grid-row">

                                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                                &nbsp;
                                            </div>
                                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">

                                                <Link onClick={() => {
                                                    setShowDetails(!showDetails)
                                                }} underline>
                                                    {t("Common.Button.See more details", {ns: 'common'})}
                                                </Link>

                                            </div>
                                        </div>
                                    }
                                    {showDetails &&
                                        <>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                                    <label>
                                                        <strong>{t("ImportExternalComponent.Label Author", {ns: 'importExternalComponent'})}</strong>
                                                    </label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                                    {applicationFileObject ? applicationFileObject.Author : ""}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                                    <label>
                                                        <strong>{t("ImportExternalComponent.Label Source", {ns: 'importExternalComponent'})}</strong>
                                                    </label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                                    {applicationFileObject ? applicationFileObject.AppName : ""}

                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">

                                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                                    <label>
                                                        <strong>{t("ImportExternalComponent.Label Exported Date", {ns: 'importExternalComponent'})}</strong>
                                                    </label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                                                    {applicationFileObject ? applicationFileObject.Exported : ""}
                                                </div>
                                            </div>


                                        </>

                                    }
                                </>
                            }
                            {/*End Details row*/}
                            <br/>

                            {/*Progress Indicator*/}
                            {isImporting &&
                                <div className="ms-Grid-row">
                                    <ProgressIndicator
                                        label={t("ImportExternalComponent.ProgressIndicator Title", {ns: 'importExternalComponent'})}
                                        description={t("ImportExternalComponent.ProgressIndicator Description", {ns: 'importExternalComponent'})}/>
                                </div>
                            }
                            {/*End Progress Indicator*/}
                        </div>
                    </div>
                    {/*End Body*/}

                    <Separator/>

                    {/*Footer*/}
                    <div className={contentStyles(branding.theme).footer}>
                        <DialogFooter>
                            <PrimaryButton disabled={isImporting || isFormInvalid } onClick={importOnCLick}
                                           text={t("ImportExternalComponent.Button Import", {ns: 'importExternalComponent'})}
                                           title={t("ImportExternalComponent.Button Import Title", {ns: 'importExternalComponent'})}/>
                            <DefaultButton onClick={hideModal} text={t("Common.Button.Cancel", {ns: 'common'})}
                                           disabled={isImporting}
                                           title={t("Common.Button.Cancel", {ns: 'common'})}/>
                        </DialogFooter>
                    </div>
                    {/*End Footer*/}
                </form>
            </Modal>
        </div>
        /*End Modal*/
    )
}

export default ImportExternalPackage
