export interface ITemplateType {
    Id: string;
    AcountId: string;
    TemplateId: string;
    FileName: string;
    Description: string;
    DisplayName: string;
    Category: string;
    DisplayNameToolTip: string;
    TemplateContent: string;
    OwnerFullName: string;
    OwnerDisplayName: string;
    OwnerUserName: string;
}

export const emptyTemplate: ITemplateType = {
    Id: "",
    AcountId: "",
    TemplateId: "",
    FileName: "",
    Description: "",
    DisplayName: "",
    Category: "",
    DisplayNameToolTip: "",
    TemplateContent: "",
    OwnerFullName: "",
    OwnerDisplayName: "",
    OwnerUserName: ""
}

/**
 * Parse file template to import 
 * @param template
 */
export const parseTemplate = (template: string): ITemplateType => {
    try {
        return JSON.parse(template) as ITemplateType;
    } catch (e) {
        console.error("Error pasing JSON:", e);
        return emptyTemplate;
    }
}
