import { useContext } from "react"
import { context } from "../context/WizardContext"
import { ILayout } from "../../CustomTemplate"
import { DEFAULT_EDIT_MODE, FormAssemblyActions, FormRuleEditMode, IFormAssemblyRule } from "../../../../interfaces/IFormAssembly"
import React from "react"
import { IStatusMessage } from "../../../../interfaces/IApp"

export const useFormAssembly = () => {

    const { editMode, setEditMode, items: layouts, formRules,
        setFormRules } = useContext(context)

    const onEditModeChange = (editMode: FormRuleEditMode) => {
        const { rule } = editMode
        let contentControls: ILayout[] | undefined = []
        if (!rule) {
            return setEditMode(() => {
                return { ...editMode, contentControls: [], rule: undefined }
            })
        }
        if (editMode.type === 'RULES_CONDITION') {
            contentControls = rule?.conditions?.map((condition) => {
                return layouts.find(layout => layout.Id === condition.field) ?? {} as ILayout
            })
        } else if (editMode.type === 'RULES_ACTIONS') {
            contentControls = rule?.actions.filter(a => a.type === 'field')?.map((action) => {
                return layouts.find(layout => action.type === 'field' && layout.Id === action.value) ?? {} as ILayout
            })
        }

        setEditMode(() => {
            return { ...editMode, contentControls: contentControls ?? [], rule: rule }
        })
    }

    const addFormAssemblyCC = (control: ILayout, isAdding: boolean) => {
        setEditMode((_editMode) => {

            const items = isAdding ? [..._editMode.contentControls, control] : _editMode.contentControls.filter(x => x.Id !== control.Id)

            return { ..._editMode, contentControls: items, rule: editMode.rule }
        })
    }
    const resetEditMode = () => {
        setEditMode((prev) => ({...prev, ...DEFAULT_EDIT_MODE }))
    }


    const handleFormAssembly = (option: FormAssemblyActions, rule: IFormAssemblyRule): IStatusMessage => {
        const item = formRules.find(i => i.ruleName.toLowerCase() === rule.ruleName.toLowerCase())
        if (option !== 'CREATE' && item === undefined)
            return { Message: <>That rule is not exists</>, Type: 'error', HasTimeOut: true }

        switch (option) {
            case "CREATE":
                if (item) {
                    return { Message: <>That rule already exists</>, Type: 'error', HasTimeOut: true }
                }
                setFormRules((prev) => [...prev, rule])
                break;
            case "EDIT":
                setFormRules((prev) => {
                    const index = prev.findIndex(item => item.ruleName === rule.ruleName)
                    prev[index] = rule
                    return [...prev]
                })
                break
            case "SAVE_ACTIONS":
                setFormRules((prev) => {
                    const index = prev.findIndex(item => item.ruleName === rule.ruleName)
                    prev[index].actions = [...rule.actions]
                    return [...prev]

                })
                break
            case "SAVE_CONDITIONS":
                setFormRules((prev) => {
                    const index = prev.findIndex(item => item.ruleName === rule.ruleName)
                    if (Array.isArray(rule.conditions))
                        prev[index].conditions = [...rule.conditions]
                    //else
                    //prev[index].conditions = { ...rule.conditions }
                    return [...prev]
                })
                break
            case "DELETE":
                setFormRules((prev) => {
                    return prev.filter(item => item.ruleName !== rule.ruleName)
                })
                break
        }
        return { Message: <>That rule is already exists</>, Type: 'success', HasTimeOut: true }
    }
    return {
        editMode,
        setEditMode,
        resetEditMode,
        layouts,
        formRules,
        setFormRules,
        onEditModeChange,
        addFormAssemblyCC,
        handleFormAssembly
    }
}