import { isMobile } from "react-device-detect";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
export const getRecordDescription = (title: string, description: string) => {
    let _description: string =
        description && description !== "null" ? `(${description.trim()})` : "";
    return isMobile ? title : `${title} ${_description}`;
};
export const formatString = (value: string): string => {
    return value.replace(/[^0-9A-Za-z() _:;,-\[\]\$]/g, "");
};
export type SelectOptions = {
    value: string;
    label: string;
};
export const filterOptionFromValue = (
    option: FilterOptionOption<SelectOptions | null>,
    inputValue: string
): boolean => {
    if (option?.data) {
        return option.data.label
            .toLowerCase()
            .includes(inputValue.toLowerCase());
    }
    return false;
};

export const sortArray = (array: any[], property: string) => {
    return array.sort((a, b) => {
        if (a[property] > b[property]) {
            return 1;
        } else if (a[property] < b[property]) {
            return -1;
        }
        return 0;
    });
};
type GroupedItems<T> = {
    [key:string]: T[];
}

export const groupByProperty = <T>(items: T[], property: keyof T): GroupedItems<T> =>
    items.reduce((groups: GroupedItems<T>, item: T) => {
        const val = item[property]
        if (val === undefined) return groups
        groups[val as keyof unknown] = groups[val as keyof unknown] || []
        groups[val as keyof unknown]?.push(item)
        return groups;
    }, {})
