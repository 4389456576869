import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { Checkbox, FontIcon } from "@fluentui/react";
import Select, {
    GroupBase,
    MultiValue,
    MultiValueGenericProps,
    OptionProps,
} from "react-select";
import { MultiSelectStyles, customSelectDropdownMultiStyles, groupBadgeStyles, groupStyles } from "./Styles";
import { SelectProps } from "./Interfaces/Select";
import useSelectMultipleControl from "./hooks/useSelectMultipleControl";
import { isMobile } from "react-device-detect";
import MobileSelect from "./MobileSelect";
import { IMyListOption } from "../../../../Helpers/Helper";

export const RHFSelectMultipleControl: FC<
    ReactHookFormControlProps & SelectProps & { fieldValue: any }
> = (props) => {

    const { options, mapDefaultValue, selectedOptions, onChangeDropdown } = useSelectMultipleControl({
        id: props.id, options: props.options, defaultValue: props.defaultValue, value: props.fieldValue
    })



    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => (
                <>
                    {props.onRenderLabel()}
                    {isMobile ? <MobileSelect
                        required={props.required ?? false}
                        readOnly={props.readOnly ?? false}
                        isMultiple
                        tabIndex={props.tabIndex}
                        defaultValue={selectedOptions}
                        placeholder={props.placeholder || ""}
                        onChange={(newValue) => {
                            onChangeDropdown(
                                newValue as MultiValue<IMyListOption>
                            );
                        }} options={options} name={props.id}
                        label={props.label} /> :
                        <Select
                            id={props.id}
                            value={selectedOptions}
                            options={options}
                            onChange={(newValue, actionMeta) => {
                                onChangeDropdown(
                                    newValue
                                );
                            }}
                            placeholder={props.placeholder || ""}
                            isClearable
                            isSearchable
                            required={props.required}
                            isDisabled={props.readOnly}
                            isMulti
                            closeMenuOnSelect={false}
                            menuPosition="fixed"
                            tabIndex={props.tabIndex}
                            menuPlacement="auto"
                            minMenuHeight={200}
                            maxMenuHeight={327}
                            styles={customSelectDropdownMultiStyles}
                            hideSelectedOptions={false}
                            formatGroupLabel={formatGroupLabel}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <FontIcon
                                        iconName="ChevronDown"
                                        style={{
                                            paddingRight: "7px",
                                            fontSize: "12px",
                                        }}
                                    />
                                ),
                                // @ts-ignore
                                Option,
                                // @ts-ignore
                                MultiValueContainer,
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#EDEBE9",
                                    primary: "#EDEBE9",
                                },
                            })}
                        />
                    }
                    <div className={MultiSelectStyles.error}>
                        {error &&
                            (props.getValues(props.id) === undefined ||
                                props.getValues(props.id)?.length <= 0)
                            ? error.message
                            : null}
                    </div>
                </>
            )}
        />
    );
};

const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const MultiValueContainer = ({
    selectProps,
    data,
}: MultiValueGenericProps<any, true, GroupBase<any>>) => {
    const values = selectProps.value;
    if (values) {
        return values[values.length - 1].label === data.label
            ? data.label
            : data.label + ", ";
    } else return "";
};

const Option = (props: OptionProps) => {
    const { innerProps, innerRef } = props;
    return (
        <div
            className={MultiSelectStyles.option}
            style={{
                backgroundColor: props.isSelected ? "rgb(237, 235, 233)" : "#ffffff",
            }}
            ref={innerRef}
            {...innerProps}
        >
            {props.isSelected ? (
                <>
                    <Checkbox
                        className={MultiSelectStyles.checkbox}
                        checked={true}
                        disabled
                        styles={{
                            checkbox: {
                                backgroundColor: "rgb(0, 120, 212)",
                                border: "0px",
                            },
                        }}
                    />
                    <p style={{ alignItems: "center", margin: 0 }}>{props.label}</p>
                </>
            ) : (
                    <>
                        <Checkbox
                            className={MultiSelectStyles.checkbox}
                            checked={false}
                            disabled
                        />
                        <p style={{ alignItems: "center", margin: 0 }}>{props.label}</p>
                    </>
                )}
        </div>
    );
};