import { ILayout } from '../../CustomTemplate';
import { getNumberFromString, isNumericString } from "../../../../Helpers/Utils";
import { FormAssemblyOperator, ICondition, IFormAssemblyRule, IRuleElement } from "../../../../interfaces/IFormAssembly";
import { InputType } from '../../Validations';
import { parseISOToDate, getISODateString, diffDates, getDateFromFormat, getDateWithFormat } from '../../../../Helpers/DateUtils';
import { cleanHtml } from '../../CustomForm/RHFControls/utils/functions';
import { IDropdownOption } from '@fluentui/react';
import { evaluate } from 'mathjs'

export const getFormAssemblyCompareOptions = (type: InputType) => {

    let operators = []

    switch (type) {
        case InputType.CheckBox:
            operators = [['Equal', FormAssemblyOperator.Equal], ['NotEqual', FormAssemblyOperator.NotEqual]]
            break
        case InputType.Currency:
        case InputType.Number:
            operators = Object.entries(FormAssemblyOperator).filter(([key]) => key !== 'Include')
            break
        default:
            operators = Object.entries(FormAssemblyOperator)
            break
    }
    return operators.map(([key, value]) => {
        return {
            key: key,
            text: value
        }
    })
}
export const getFormAssemblyActionOptions = (type: InputType): IDropdownOption[] => {
    switch (type) {
        case InputType.Integration:
        case InputType.LineBreak:
        case InputType.SectionHeader:
        case InputType.Paragraph:
        case InputType.Attachment:
        case InputType.GridList:
        case InputType.HelpLink:
        case InputType.Image:
            return [
                { key: 'lock', text: 'Lock' },
                { key: 'hide', text: 'Hide' },
                { key: 'unlock', text: 'Unlock' },
                { key: 'show', text: 'Show' },
            ]
        default:
            return [
                { key: 'lock', text: 'Lock' },
                { key: 'hide', text: 'Hide' },
                { key: 'unlock', text: 'Unlock' },
                { key: 'show', text: 'Show' },
                { key: 'value', text: 'Value' },
            ]
    }
}
export function evaluateFormAssembly<T>(rules: IFormAssemblyRule[], isAdmin: boolean, data: T, layouts: ILayout[]) {
    //const rulesToEvaluate = rules.filter(rule => rule.conditions.find(c=>field.find))
    const actionsToRun = rules.map(rule => {
        if (rule.adminOnly && !isAdmin) return []
        const ruleToEvaluate = evaluateConditionRules(rule.conditions, data, layouts)
        const ruleEvaluated = evaluate(ruleToEvaluate)
        //console.log('ruleEvaluated', ruleToEvaluate)
        if (ruleEvaluated) {
            return rule.actions
        }
        return []
    })

    return actionsToRun.flat()
}
// function evaluateConditionRules<T>(condition: IRuleElement[], data: T): boolean {

//     return condition.every(rule => {
//         if ('field' in rule) {
//             return evaluateRule(rule, data);
//         } else {
//             return evaluateCondition(rule, data);
//         }
//     });
// }
function evaluateConditionRules<T>(conditions: IRuleElement[], data: T, layouts: ILayout[]): string {

    let rules: string[] = []
    for (const rule of conditions) {
        const ruleResult = evaluateRule(rule, data, layouts);
        const operatorGroups = { AND: '&', OR: '|' }
        const resultGroup = rule.operatorGroup ? operatorGroups[rule.operatorGroup] : ''
        rules.push(`${rule.parenOpen}${ruleResult}${rule.parenClose}${resultGroup}`)
    }
    return `(${rules.join('')})`
}

function applyOperator(operator: 'AND' | 'OR', leftOperand: boolean, rightOperand: boolean): boolean {
    //console.log(operator, 'left', leftOperand, 'right', rightOperand)

    if (operator === 'AND') {
        return leftOperand && rightOperand;
    } else {
        return leftOperand || rightOperand;
    }
}

function evaluateRule<T>(rule: IRuleElement, data: any, layouts: ILayout[]): boolean {
    const fieldValue = data[rule.field]
    const layoutItem = layouts.find(l => l.Id === rule.field)

    if (!layoutItem)
        return false
    const operatorKey = Object.keys(FormAssemblyOperator).find(
        (key) => key === rule.operator
    )
    //fieldValue, `${rule.value}`,
    const op = FormAssemblyOperator[operatorKey as keyof typeof FormAssemblyOperator]
    const { left, right }: any = getLeftAndRightData(layoutItem)?.(fieldValue, `${rule.value}`)
    const callback = getCallback(layoutItem)
    return evaluateConditionCallback(left, right, op, callback)

}

const getCallback = (layout: ILayout) => {
    switch (layout.Type) {
        case InputType.DatePicker: {
            return (a: any, b: any) => {
                return diffDates(a, b, 'days')
            }
        }
        case InputType.DropDownList: {
            return (a: any, b: string) => {
                if (isNumericString(a) && isNumericString(b)) {
                    return Number(a) - Number(b)
                }
                return a.toString().localeCompare(b)
            }
        }
        case InputType.CheckBoxList: {
            return (a: any, b: string) => {
                return a.toString().localeCompare(b)
            }
        }
        case InputType.RichText: {
            return (a: any, b: string) => {
                return a.toString().localeCompare(b)
            }
        }
        case InputType.Currency:
        case InputType.Number: {
            return (a: any, b: string) => {
                return Number(a) - Number(b)
            }
        }
    }
    return (a: any, b: string) => a?.toString().localeCompare(b)
}
const evaluateConditionCallback = (leftValue: any, rightValue: string, operator: FormAssemblyOperator, callback: Function) => {

    if (leftValue === undefined && rightValue === undefined) {
        return false
    }
    const value = callback(leftValue, rightValue)
    switch (operator) {
        case FormAssemblyOperator.Equal:
            return value === 0
        case FormAssemblyOperator.NotEqual:
            return value !== 0
        case FormAssemblyOperator.GreaterThan:
            return value > 0
        case FormAssemblyOperator.GreaterThanOrEqual:
            return value >= 0
        case FormAssemblyOperator.LessThan:
            return value < 0
        case FormAssemblyOperator.LessThanOrEqual:
            return value <= 0
        case FormAssemblyOperator.Include: {

            return leftValue?.toString().toLowerCase().includes(rightValue?.toLowerCase());
        }

        default:
            return false;
    }
}
const getLeftAndRightData = (layout: ILayout) => {
    switch (layout.Type) {
        case InputType.DatePicker: {
            const callback = (a: any, b: string) => {
                let _leftDate = new Date(a)
                const _a = parseISOToDate(getDateWithFormat(_leftDate, layout.Validations.Regex))

                const _rightDate: Date = parseISOToDate(getDateFromFormat(b, layout.Validations.Regex))
                if (_rightDate === undefined) {
                    return { left: undefined, right: undefined }
                }
                return { left: _a, right: _rightDate }
            }
            return callback
        }
        case InputType.Currency:
        case InputType.Number: {

            return (a: any, b: string) => {
                const _a = getNumberFromString(a)
                const _b = getNumberFromString(b)
                return { left: _a, right: _b }
            }
        }
        case InputType.DropDownList: {
            const callback = (a: any, b: string) => {
                let _a = a ?? ''
                if (_a.hasOwnProperty('key')) {
                    _a = a.text
                }
                return { left: _a, right: b }
            }
            return callback
        }
        case InputType.CheckBoxList: {
            const callback = (a: any, b: string) => {
                let _a = a ?? ''
                let _b = b ?? ''
                if (Array.isArray(_a)) {
                    _a = _a.map(x => x.text.trim()).sort((a, b) => a.toString().localeCompare(b)).join(',')
                }
                _b = b.split(',').map(x => x.trim()).sort((a, b) => a.toString().localeCompare(b)).join(',')
                return { left: _a, right: _b }
            }
            return callback
        }
        case InputType.RichText: {
            const callback = (a: any, b: string) => {
                let _a = cleanHtml(a)
                return { left: _a, right: b }
            }
            return callback
        }
        default:
            return (a: any, b: string) => {
                return { left: a, right: b }
            }
    }
}
export const validateConditionRules = (conditions: IRuleElement[]) => {
    const stack: string[] = []
    for (let i = 0; i < conditions.length; i++) {
        const rule = conditions[i];
        if (rule.parenOpen === '(') {
            stack.push('(');
        }
        if (rule.parenClose === ')') {
            if (stack.pop() !== '(') {
                return false
            }
        }
        if (rule.operatorGroup === '') {
            if ((i === 0 && conditions.length > 1) || (conditions[i + 1])) {
                return false
            }
        }
    }

    return stack.length === 0
}
