import React, { useMemo, useState } from 'react'
import { ILayout } from '../../CustomTemplate'
import { t } from 'i18next'
import { IconButton, Checkbox, Dropdown, TextField, IDropdownOption, IIconProps, IDropdownStyles, mergeStyleSets, DefaultButton, DialogFooter, PrimaryButton } from 'office-ui-fabric-react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FormAssemblyOperator, ICondition, IFormAssemblyRule, IRuleElement } from '../../../../interfaces/IFormAssembly'
import { IStatusMessage } from '../../../../interfaces/IApp'
import { useFormAssembly } from '../hooks/useFormAssembly'
import CustomInput from '../../CustomForm/RHFControls/utils/CustomInput'
import { getFormAssemblyCompareOptions, validateConditionRules } from './utils'
import StatusMessage from '../../../../share/StatusMessage'

type ContidionModalProps = {
    close: () => void
}
type DropdownFieldsType = keyof IRuleElement
const defaultCompareOptions: IDropdownOption[] = Object.entries(FormAssemblyOperator).map(([key, value]) => {
    return {
        key: key,
        text: value
    }
})
const conditionOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: 'AND', text: 'AND' },
    { key: 'OR', text: 'OR' },
]
const pharenInnerOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: '(', text: '(' },
]
const pharenOutherOptions: IDropdownOption[] = [
    { key: '', text: '' },
    { key: ')', text: ')' },
]
type FormAssemblyType = {
    rules: IRuleElement[]
}
export function ConditionModal({ close }: ContidionModalProps) {
    const { editMode, handleFormAssembly } = useFormAssembly()
    const { contentControls } = editMode
    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)

    const { control, handleSubmit, reset, setValue, trigger } = useForm<FormAssemblyType>({
        defaultValues: useMemo(() => {
            const items: IRuleElement[] = contentControls.map((item, index, array) => {
                const condition = editMode.rule?.conditions.find((condition) => condition.field === item.Id)
                return {
                    field: item.Id,
                    parenOpen: condition?.parenOpen ?? '',
                    parenClose: condition?.parenClose ?? '',
                    operatorGroup: condition?.operatorGroup ?? (index < array.length - 1 ? 'AND' : ''),
                    operator: condition?.operator ?? FormAssemblyOperator.Equal,
                    value: condition?.value ?? ''
                }
            })
            return { rules: items }
        }, [contentControls])
    })
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "rules",
    })
    const dropdownOptions = useMemo<IDropdownOption[]>(() => {
        const items: IDropdownOption[] = contentControls.map(item => {
            return {
                key: item.Id,
                text: item.Label
            }
        })
        return items
    }, [contentControls])

    const handleDropdown = (fieldName: DropdownFieldsType, index: number, event: React.FormEvent, option?: IDropdownOption | undefined) => {
        //@ts-ignore
        setValue(`rules.${index}.${fieldName as keyof IRuleElement}` as const, `${option?.key}`)
    }
    const handleShowMessage = () => {
        setMessage(undefined)
    }
    const saveConditions = (data: FormAssemblyType) => {
        const isValid = validateConditionRules(data.rules)
        if (!isValid) {
            setMessage({ Type: 'error', Message: <>Error condition is invalid: Verify all parenthesis and AND/OR operators.</> })
            return
        }
        if (editMode.rule) {
            const rule: IFormAssemblyRule = { ...editMode.rule, conditions: data.rules }
            handleFormAssembly('SAVE_CONDITIONS', rule)
        }
        close()
    }
    return (
        <>
            {message ? <StatusMessage setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            <h2>Choose which condition should be true using the content controls selected</h2>
            {fields.length === 0 ?
                <em>Please, select at least one content control</em>
                :
                <form onSubmit={handleSubmit(saveConditions)}>
                    <table className={contentStyles.table}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Content Control</th>
                                <th>Compare</th>
                                <th>Compare Value</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {fields.map((item, index) => {
                                const contentControl = contentControls.find(cc=>cc.Id === item.field)
                                console.log('contentControl', item)
                                const compareOptions = contentControl ? getFormAssemblyCompareOptions(contentControl?.Type) : defaultCompareOptions
                                return (
                                    <tr key={item.id}>
                                        {/* <td>
                                            <IconButton iconProps={deleteIcon} disabled={index === 0} title="Remove" ariaLabel="Remove" onClick={() => { }} />
                                        </td> */}
                                        <td>
                                            <Controller name={`rules.${index}.parenOpen` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    {...field}
                                                    onChange={(event, option) => handleDropdown('parenOpen', index, event, option)}
                                                    options={pharenInnerOptions}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`rules.${index}.field` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    styles={fieldNameDropdown}
                                                    {...field}
                                                    onChange={(event, option) => handleDropdown('field', index, event, option)}
                                                    options={dropdownOptions}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`rules.${index}.operator` as const} control={control}
                                                render={({ field }) => <Dropdown styles={compareDropdown}
                                                    selectedKey={field.value}
                                                    {...field}
                                                    onChange={(event, option) => handleDropdown('operator', index, event, option)}
                                                    options={compareOptions}
                                                />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`rules.${index}.value` as const} control={control}
                                                render={({ field }) => <TextField {...field} value={(field.value as string)} />}
                                            />
                                        </td>
                                        <td>
                                            <Controller name={`rules.${index}.parenClose` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    {...field}
                                                    onChange={(event, option) => handleDropdown('parenClose', index, event, option)}
                                                    options={pharenOutherOptions}
                                                />}
                                            />
                                        </td>
                                        {index < fields.length - 1 && <td>
                                            <Controller name={`rules.${index}.operatorGroup` as const} control={control}
                                                render={({ field }) => <Dropdown selectedKey={field.value}
                                                    {...field}
                                                    onChange={(event, option) => handleDropdown('operatorGroup', index, event, option)}
                                                    options={conditionOptions}
                                                />}
                                            />
                                        </td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton id="btnCreateUpdate" type="submit" text="Save Conditions" disabled={false} title="Save Conditions" />
                                <DefaultButton id="btnCancel" onClick={close} text={'Cancel'} title={'Cancel'} />
                            </DialogFooter>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}
const deleteIcon: IIconProps = { iconName: 'Delete' }
const fieldNameDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 150, maxWidth: 300 },
}
const compareDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 150, maxWidth: 300 },
}
const contentStyles = mergeStyleSets({
    table: {
        width: '100%'
    },

})