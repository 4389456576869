import { ILayout } from "../Components/CustomTemplates/CustomTemplate"

export type FormRuleEditMode = {
    type: 'RULES_CONDITION' | 'RULES_ACTIONS' | 'RULE_PREVIEW' | undefined
    rule: IFormAssemblyRule | undefined
    showBar: boolean
    contentControls: ILayout[]
}
export const DEFAULT_EDIT_MODE: FormRuleEditMode = { type: undefined, contentControls: [], rule: undefined, showBar: false }
export type FormAssemblyActions = 'CREATE' | 'EDIT' | 'DELETE' | 'SAVE_CONDITIONS' | 'SAVE_ACTIONS'
export enum FormAssemblyOperator {
    Equal = 'Equal',
    NotEqual = 'Not Equal',
    GreaterThan = 'Greater than',
    GreaterThanOrEqual = 'Greater than or equal',
    LessThan = 'Lower than',
    LessThanOrEqual = 'Lower than or equal',
    Include = 'Contains'
}
export enum ActionType {
    Lock = 'lock',
    Hide = 'hide',
    Unlock = 'unlock',
    Show = 'show',
    Value = 'value'
}
export enum TabActionType {
    Hide = 'hide',
    Show = 'show'
}
export type IFormAssemblyRule = {
    ruleName: string;
    conditions: IRuleElement[]
    actions: IAction[];
    adminOnly?: boolean
}

export interface ICondition {
    type: 'and' | 'or';

    rules: (IRuleElement | ICondition)[];
}

export interface IRuleElement {
    field: string;
    operatorGroup: 'AND' | 'OR' | ''
    parenOpen: '(' | ''
    parenClose: ')' | ''
    operator: FormAssemblyOperator;
    value: string | number;
}

export type IAction = {
    type: 'field'
    value?: string
    fieldName: string
    actionType: ActionType
    fieldValue?: string
} | {
    type: 'tab',
    tabName: string
    value: string
    actionType: TabActionType
}