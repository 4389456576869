import React, { useMemo, useRef, useState } from 'react'
import { DefaultButton, DialogFooter, Dropdown, IDropdownOption, IDropdownStyles, IIconProps, Icon, IconButton, Label, PrimaryButton, TextField, mergeStyleSets } from '@fluentui/react'
import { e, index } from 'mathjs'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { ActionType, IAction, IFormAssemblyRule, TabActionType } from '../../../../interfaces/IFormAssembly'
import { IStatusMessage } from '../../../../interfaces/IApp'
import { useFormAssembly } from '../hooks/useFormAssembly'
import { getFormAssemblyActionOptions } from './utils'

const deafultModeOptions: IDropdownOption[] = [
    { key: 'lock', text: 'Lock' },
    { key: 'hide', text: 'Hide' },
    { key: 'unlock', text: 'Unlock' },
    { key: 'show', text: 'Show' },
    { key: 'value', text: 'Value' },
]
const tabOptions: IDropdownOption[] = Object.values(TabActionType).map((item) => {
    return {
        key: item,
        text: item
    }
})
type FormAssemblyType = {
    actions: IAction[]

}

const isFieldAction = (action: IAction | undefined): action is IAction & { type: 'field' } => {
    if (!action) return false
    return action.type === 'field'
}
type ActionModalProps = {
    totalPages: number
    close: () => void
}
type ActionTypeKeys = "value" | "fieldName" | "actionType"
export function ActionModal({ totalPages, close }: ActionModalProps) {

    const { editMode, handleFormAssembly } = useFormAssembly()
    const { contentControls } = editMode
    const { control, handleSubmit, reset, setValue, trigger, getValues, watch } = useForm<FormAssemblyType>({
        defaultValues: useMemo(() => {

            const items: IAction[] = contentControls.map((item) => {
                const action = editMode.rule?.actions?.find((action) => isFieldAction(action) && action.value === item.Id)
                return {
                    type: 'field', value: item.Id, fieldName: item.Label, actionType: action?.actionType as ActionType ?? ActionType.Hide,
                    fieldValue: isFieldAction(action) ? action.fieldValue : ''
                }
            })
            const tabs = editMode.rule?.actions.filter(a => a.type === 'tab') ?? []
            return { actions: [...items, ...tabs] }
        }, [contentControls, editMode.rule])
    })
    const [pageOption, setPageOption] = useState('0')
    const [tabAddErrorMsg, setTabAddErrorMsg] = useState('')

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "actions",
    })
    const handleDropdown = (fieldName: 'actionType', index: number, event: React.FormEvent, option?: IDropdownOption | undefined) => {
        handleActionType(index, option?.key as string)
    }
    const handleActionType = (index: number, value: string) => {
        //@ts-ignore
        setValue(`actions.${index}.actionType` as const, value as ActionType);
    }
    const addTabAction = () => {
        const tabItem = fields.find((item) => item.type === 'tab' && item.value === pageOption)
        if (!tabItem) {
            setTabAddErrorMsg('')
            return append({ type: 'tab', tabName: `Tab ${Number(pageOption) + 1}`, value: pageOption, actionType: TabActionType.Hide })
        }
        setTabAddErrorMsg(`Tab ${Number(pageOption) + 1} already exists`)
    }
    const saveActions = (data: FormAssemblyType) => {
        console.log('saveActions', data)
        if (editMode.rule) {
            const rule: IFormAssemblyRule = { ...editMode.rule, actions: data.actions }
            handleFormAssembly('SAVE_ACTIONS', rule)
        }
        close()
    }
    const pagesArray: IDropdownOption[] = Array.from(Array(totalPages).keys()).map((page) => {
        return {
            key: page,
            text: `Tab ${page + 1}`
        }
    })
    return (
        <>

            <p>Choose which actions should run per Content Control selected</p>
            <form onSubmit={handleSubmit(saveActions)}>
                <div className="ms-Grid" dir="ltr">

                    <h2>Field rules</h2>
                    <div className="ms-Grid-row">
                        {
                            fields.filter((item) => isFieldAction(item)).map((item) => {

                                if (isFieldAction(item)) {
                                    const contentControl = contentControls.find(cc => cc.Id === item.value)
                                    const modeOptions = contentControl ? getFormAssemblyActionOptions(contentControl?.Type) : deafultModeOptions
                                    const index = fields.findIndex((field) => isFieldAction(field) && field.fieldName === item.fieldName)
                                    const actionType = watch(`actions.${index}.actionType` as const)
                                    return (
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mb-4" key={item.id}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <Label>{item.fieldName}</Label>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    {actionType === ActionType.Value ?
                                                        <div className={contentStyles.inputLabel}>
                                                            <IconButton className="mr-2" iconProps={BackIcon} primary
                                                                onClick={() => handleActionType(index, ActionType.Hide)}
                                                                title="Delete" ariaLabel="Delete" />
                                                            <div className={contentStyles.inputWrapper}>
                                                                <Controller name={`actions.${index}.fieldValue` as const} control={control}
                                                                    render={({ field }) => <TextField style={{ width: '100%' }} {...field} />}
                                                                />
                                                            </div>

                                                        </div>
                                                        : <Controller name={`actions.${index}.actionType` as const} control={control}
                                                            render={({ field }) => <Dropdown styles={compareDropdown}
                                                                selectedKey={field.value}
                                                                {...field}
                                                                onChange={(event, option) => handleDropdown('actionType', index, event, option)}
                                                                options={modeOptions}
                                                            />}

                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            })
                        }
                    </div>
                    <h2>Tab rules</h2>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 mb-4">
                            <div className={contentStyles.inputLabel}>
                                <Label className="mr-2">Select Tab:</Label>
                                <Dropdown styles={compareDropdown}
                                    options={pagesArray} onChange={(event, option) => setPageOption(option?.key.toString() ?? '0')}
                                />
                                <div className="ml-4">
                                    <PrimaryButton onClick={addTabAction}>Add</PrimaryButton>
                                </div>
                            </div>
                            {tabAddErrorMsg && <span className={contentStyles.error}>{tabAddErrorMsg}</span>}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        {
                            fields.filter((item) => item.type === 'tab').map((item, index) => {
                                if (!isFieldAction(item)) {
                                    const index = fields.findIndex((field) => field.type === 'tab' && field.tabName === item.tabName)
                                    return (
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mb-4" key={item.id}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ">
                                                    <div className={contentStyles.inputLabel}>
                                                        <IconButton className="mr-2" iconProps={DeleteIcon}
                                                            onClick={() => remove(index)}
                                                            title="Delete" ariaLabel="Delete" />
                                                        <Label>
                                                            {item.tabName}
                                                        </Label>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col">
                                                    <Controller name={`actions.${index}.actionType` as const} control={control}
                                                        render={({ field }) => <Dropdown styles={compareDropdown}
                                                            selectedKey={field.value}
                                                            {...field}
                                                            onChange={(event, option) => handleDropdown('actionType', index, event, option)}
                                                            options={tabOptions}
                                                        />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            })
                        }
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton id="btnCreateUpdate" type="submit" text="Save Actions" disabled={false} title="Save Actions" />
                                <DefaultButton id="btnCancel" onClick={close} text={'Cancel'} title={'Cancel'} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
const DeleteIcon: IIconProps = { iconName: 'Delete' };
const BackIcon: IIconProps = { iconName: 'ChromeBack' };
const compareDropdown: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 150, maxWidth: 300 },
}
const contentStyles = mergeStyleSets({

    table: {
        width: '100%'
    },
    inputLabel: {
        display: 'flex'
    },
    inputWrapper: {
        flexGrow: 1,
    },
    error: {
        color: '#a4262c'
    }
})