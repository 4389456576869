import HttpRequest from "./HttpRequest";
import Helper from "../Helpers/Helper";


export interface IApplicationContextResult {
    AppLogo:string
    AppName:string
    AppTagline:string
    AppiconLogo:string
    AppTheme:string
    HelpPath:string    
    CreateExampleApplication:string
    CreateExampleRecord:string
    ExampleUniqueReferenceToImport:string
    ShowGetStarted:string
    ShowDefaultExamplesSection:string
    ShowCustomExamplesLink:string
    CustomExamplesLink:string
    CustomExamplesLinkTarget:string
    ShowWebformUseCasesLink:string
    WebformUseCasesLink:string
    WebformUseCasesLinkTarget:string
    ShowFAQLink:string
    FAQLink:string
    FAQLinkTarget:string
    SupportEmail:string
    ShareEmail:string
}

const ApplicationContextService = {


    /**
     * ApplicationContext
     * @constructor
     */

    ApplicationContext: async (): Promise<IApplicationContextResult> => {
        let {data}: any = await HttpRequest.get('ApplicationContext', {})

        return new Promise<IApplicationContextResult>((resolve, reject) => {
            if (!data.Success) reject(Helper.GetErrorFromResponse(data))
            resolve(JSON.parse(data.Result) as IApplicationContextResult)
        })
    },
}

export default ApplicationContextService