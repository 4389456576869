import { useMemo, useRef } from 'react';
import { useContext } from 'react'
import { UseFormReturn } from 'react-hook-form';
import { IDropdownOption } from '@fluentui/react';
import { ILayout } from '../../CustomTemplate';
import { InputType } from '../../Validations';
import { IFormControl } from '../../../../Helpers/Helper';
import { DisplayType } from '../../../OutboundIntegration/Models/Enums';


import { cleanHtml,  } from '../RHFControls/utils/functions';
import { getDateFromFormat, getISODateString } from '../../../../Helpers/DateUtils';
import { context } from '../context/CustomFormContext';
import { useDocument } from '../../../../hooks/useDocument';

export const useCustomForm = () => {
    const { getTextboxValue } = useDocument()
    const { layouts, setLayouts, data, user, applicationResponse, documentValues,
        integrationDropDownLists, isSubform, formRules, isAdmin,
        customDropDownLists, actionsToRun: _actionsToRun, setActionsToRun } = useContext(context)

    const layoutsRef = useRef(layouts)

    const getLabel = (adminOnly: boolean, label: string): string => {
        if (adminOnly) {
            if (user.isOwner || user.isCoOwner) {
                return label;
            } else {
                return "";
            }
        } else {
            return label;
        }
    }
    const displayIntegration = (layout: ILayout) => {
        if (layout.Integration?.DisplayType === DisplayType.Always || (layout.Integration?.DisplayType === DisplayType.OnCreate && (data === null || data === "" || data === undefined)) || (layout.Integration?.DisplayType === DisplayType.OnUpdate && data !== null && data !== "" && data !== undefined)) {
            return true;
        } else {
            return false;
        }
    };
    const actionsToRun = useMemo(() => {
        return _actionsToRun
    }, [_actionsToRun.length])

    const getDefaultValue = (layout: ILayout, form: UseFormReturn<any, any>): any => {
        switch (layout.Type) {
            case InputType.RichText:
                let richValue = "";
                if (isSubform) {
                    richValue = layout.Validations.DefaultValue;
                } else {
                    let documentRichTextValue = documentValues.find(
                        (info) => info.Key === layout.Id
                    );
                    richValue =
                        documentRichTextValue !== undefined
                            ? documentRichTextValue.Value
                            : layout.Validations.DefaultValue !== ""
                                ? layout.Validations.DefaultValue
                                : "";
                }
                return richValue;
            default:
                const currentValue = form.getValues(layout.Id);
                let tempCurrentValue = currentValue !== undefined ? currentValue : layout.Validations.DefaultValue;
                if (layout.Validations?.DefaultValue?.length > 0 && (currentValue === undefined || currentValue === "")) {
                    tempCurrentValue = layout.Validations?.DefaultValue?.trim();
                }
                let formatedValue = getFormatedValue(layout, {
                    Key: layout.Id,
                    Value: tempCurrentValue,
                });
                //form.setValue(layout.Id, formatedValue);

                let gridColumns = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children), []);
                if (gridColumns.find((l) => l.Id === layout.Id) !== undefined) {
                    if (formatedValue === undefined) return undefined;
                    else if (layout.Type === InputType.DropDownList) return formatedValue.key;
                    else return formatedValue;
                }

                return tempCurrentValue;
        }
    }
    const getFormatedValue = (layout: ILayout, valueToFormat: IFormControl): any => {
        switch (layout.Type) {
            case InputType.DropDownList: {
                return valueToFormat.Value.length > 0
                    ? {
                        key:
                            layout.Integration !== undefined &&
                                layout.Integration !== null
                                ? integrationDropDownLists[
                                    layout.Integration.Id.toString()
                                ]
                                    ?.find(
                                        (item) =>
                                            item.text ===
                                            valueToFormat.Value
                                    )
                                    ?.key.toString()
                                : +layout.ListId > 0
                                    ? customDropDownLists[layout.ListId]
                                        ?.find(
                                            (item) =>
                                                item.text ===
                                                valueToFormat.Value
                                        )
                                        ?.key.toString()
                                    : valueToFormat?.Value,
                        text: valueToFormat?.Value,
                    }
                    : undefined;
            }
            case InputType.CheckBoxList: {
                return valueToFormat.Value.length > 0
                    ? valueToFormat.Value.split(",").map((text) => {
                        return {
                            key:
                                layout.Integration !== undefined &&
                                    layout.Integration !== null
                                    ? integrationDropDownLists[
                                        layout.Integration.Id.toString()
                                    ]
                                        ?.find((item) => item.text === text)
                                        ?.key.toString()
                                    : +layout.ListId > 0
                                        ? customDropDownLists[layout.ListId]
                                            ?.find((item) => item.text === text)
                                            ?.key.toString()
                                        : text,
                            text: text,
                            selected: true,
                        };
                    })
                    : undefined;
            }
            case InputType.Textbox: {
                return valueToFormat.Value != undefined &&
                    valueToFormat.Value.length > 0
                    ? getTextboxValue(valueToFormat.Value)
                    : undefined;
            }
            case InputType.TextArea: {
                return cleanHtml(valueToFormat.Value);
            }
            case InputType.CheckBox: {
                if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === layout.Id) !== undefined) {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        return "true";
                    } else {
                        return "";
                    }
                } else {
                    if (layout.Validations?.DefaultValue.length > 0) {
                        if (layout.Validations?.DefaultValue.toLowerCase() === "true") {
                            return "true"
                        } else {
                            return "false"
                        }
                    } else {
                        return "false"
                    }

                }

            }
            default: {
                return valueToFormat.Value;
            }
        }
    }
    const getFormControls = (data: any): IFormControl[] => {
        let keys = Object.keys(data);
        let values = Object.values(data);
        let formControls: IFormControl[] = [];

        keys.forEach((key, keyIndex) => {
            let internalLayouts = layouts.reduce((acc: ILayout[], el: ILayout) => acc.concat(el.Children.length > 0 ? el.Children : el), []);
            let currentLayout = internalLayouts.find((l) => l.Id === key);
            let formControl: IFormControl = { Key: key, Value: "" };
            switch (
            currentLayout === undefined
                ? InputType.Textbox
                : currentLayout.Type
            ) {
                case InputType.CheckBoxList:
                case InputType.DropDownList:
                    if (Array.isArray(values[keyIndex])) {
                        formControl.Value = values[keyIndex] === undefined ? "" : (values[keyIndex] as IDropdownOption[]).map((item) => item.text).join(",");
                        const dropdownObject = values[keyIndex] as IDropdownOption[];
                        formControl.CheckedValues = dropdownObject.map((x) => {
                            return { Key: x.key, Text: x.text };
                        })

                    } else if (values[keyIndex] instanceof Object) {
                        if (values[keyIndex] === undefined) {
                            formControl.Value = "";
                            break;
                        }
                        const dropdownObject = values[keyIndex] as IDropdownOption;
                        formControl.Value = dropdownObject.text;
                        formControl.CheckedValues = [];
                        formControl.CheckedValues = [
                            ...formControl.CheckedValues,
                            {
                                Key: dropdownObject.key,
                                Text: dropdownObject.text,
                            },
                        ];
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                case InputType.DatePicker:
                    const dateFormated =
                        values[keyIndex] != undefined
                            ? getDateFromFormat(
                                (values[keyIndex] as string,
                                    currentLayout?.Validations.Regex ?? "")
                                    .toString()
                                    .trimEnd()
                            )
                            : "";
                    formControl.Value =
                        dateFormated === ""
                            ? getISODateString(values[keyIndex] as Date)
                            : dateFormated;
                    break;
                case InputType.CheckBox:
                    if (applicationResponse![0]?.CheckBoxCCs.find((item: string) => item === currentLayout?.Id) !== undefined) {
                        if (values[keyIndex] === undefined || values[keyIndex] === "false") {
                            formControl.Value = "";
                        } else if ((values[keyIndex] as string).toString() === "true") {
                            formControl.Value = "true"
                        } else {
                            formControl.Value = "";
                        }
                    } else {
                        formControl.Value = values[keyIndex] as string;
                    }
                    break;
                case InputType.Image:
                    formControl.Value = "";
                    break;
                case InputType.Textbox:
                    formControl.Value = values[keyIndex] != undefined ? (values[keyIndex] as string).toString().trimEnd() : "";
                    break;
                default:
                    formControl.Value = values[keyIndex] as string;
            }
            formControls.push(formControl);
        });
        return formControls;
    }
    // const getWatchConditions = (id: string) => {
    //     return formRules?.reduce<({ field: string, value: string }[])>((acc, rule) => {
    //         const needsListen = rule.actions.some((r) => r.type === 'field' && r.value === id)
    //         if (needsListen) {
    //             const conditions = rule.conditions.map((r) => ({ field: r.field, value: r.value.toString() }))
    //             return [...acc, ...conditions]
    //         }
    //         return acc
    //     }, [])
    // }
    const getWatchConditions = (id: string) => {
        return formRules?.reduce<string[]>((acc, rule) => {
            const needsListen = rule.actions.some((r) => r.type === 'field' && r.value === id)
            if (needsListen) {
                const conditions = rule.conditions.map((r) => (r.field))
                return [...acc, ...conditions]
            }
            return acc
        }, [])
    }
    const getWatchTabConditions = () => {
        return formRules?.reduce<string[]>((acc, rule) => {
            const needsListen = rule.actions.some((r) => r.type === 'tab')
            if (needsListen) {
                const conditions = rule.conditions.map((r) => (r.field))
                return [...acc, ...conditions]
            }
            return acc
        }, [])
    }

    const userIsAdmin = user.isOwner || user.isCoOwner


    return {
        isAdmin,
        userIsAdmin,
        layouts,
        setLayouts,
        getLabel,
        formRules,
        displayIntegration,
        getDefaultValue,
        //getFormatedValue,
        getFormControls,
        getWatchConditions,
        getWatchTabConditions,
        //actionsToRun,
        setActionsToRun,
        layoutsRef
    }
}