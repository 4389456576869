import React, {useEffect, useState} from "react";
import {UseFormReturn} from "react-hook-form";
import {InputType} from "../../../Validations";
import {getDateWithFormat} from "../../../../../Helpers/DateUtils";
import {ICell, IColumn, IRow} from "./Models";
import {StateType} from "./Row";

export interface ICellModel {
    row: IRow;
    cell: ICell;
    column: IColumn;
    locale: string;
    rowState: StateType;
    getJSXElement: (id: string) => JSX.Element;
    setCellIdToEdit: React.Dispatch<React.SetStateAction<string>>
    gridForm: UseFormReturn<any, any>
}

const Cell = (props: ICellModel) => {
    const [localAction, setLocalAction] = useState(StateType.Read);

    useEffect(() => {
        setLocalAction(props.rowState)
        props.gridForm.trigger(`${props.cell.Id}-${props.row.Index}`)
        //props.gridForm.setValue(`${props.cell.Id}-${props.row.Index}`, props.cell.Value)
    }, [props.rowState])

    if (localAction === StateType.EditRow) {
        const element = props.getJSXElement(props.cell.Id)

        return (
            <div>
                {element}
            </div>
        );
    }
    const renderValue = (type: number, locale?: string, _culture?: string, _currency?: string) => {
        if(type === 0 && !Number.isNaN(Number(props.column.Type)) && Number(props.column.Type) === InputType.Currency ) type = InputType.Currency
        switch (type) {
            case InputType.DatePicker:
                return <>{getDateWithFormat(props.cell.Value, props.column.Validations.Regex, locale)}</>
            case InputType.Currency:
                if (typeof props.cell.Value === "string") {
                    props.cell.Value = props.cell.Value.replace(/,/g, '')
                }
                if (!Number.isNaN(Number(props.cell.Value))) {
                    let culture = null !== _culture && undefined !== _culture && _culture.length > 0 ? _culture : "en-US"
                    let currency = null !== _currency && undefined !== _currency && _currency.length > 0 ? _currency : "USD"

                    var resultCurrency = new Intl.NumberFormat(culture, {
                        style: "currency",
                        currency: currency,
                    }).format(Number(props.cell.Value))
                    return <>{resultCurrency.toString()}</>
                } else {
                    return <>{props.cell.Value}</>
                }
            default:
                return <>{props.cell.Value}</>

        }
    }

    if (localAction === StateType.Read) {
        const handleEditClick = () => {
            props.setCellIdToEdit(`${props.cell.Id}`)
        };
        return (
            <div onClick={handleEditClick}>
                {renderValue(Number(props.cell.Type), props.locale, props.column.Validations.Culture, props.column.Validations.Currency)}
            </div>
        )
    }
    return null;
};

export default Cell