import {FC, useState} from "react";
import {Controller} from "react-hook-form";
import * as React from "react";
import {ReactHookFormControlProps} from "../Models/ReactHookFormControlProps";
import {TextField, ITextFieldProps} from "@fluentui/react/lib/TextField";
import {SingleSelectStyles, textFieldStyle} from "./Styles";

export const RHFNumberControl: FC<ReactHookFormControlProps & ITextFieldProps> = (props) => {

    const [customError, setCustomError] = useState<string | undefined>(undefined)
    const [currentValue, setCurrentValue] = useState<string>(() => {
        if (Number.isNaN(Number(props.getValues(props.id)))) {
            setCustomError(props.getValues(props.id))
            return  "0"
        }
        else return  props.getValues(props.id)}
    );
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                         field: {onChange, onBlur, name: fieldName, value},
                         fieldState: {error}
                     }) => (
                <>
                    <TextField
                        id={props.id}
                        name={fieldName}
                        label={props.label}
                        onRenderLabel={props.onRenderLabel}
                        title={props.title}
                        maxLength={props.maxLength}
                        placeholder={props.placeholder}
                        errorMessage={error && error.message}
                        required={props.required}
                        disabled={props.readOnly ? props.readOnly : false}
                        value={currentValue}
                        onChange={(e) => {
                            let value = (e?.target as HTMLInputElement).value;
                            onChange(value.substring(0, props.maxLength))
                            setCurrentValue(value.substring(0, props.maxLength))
                            props.setValue(props.id, value.substring(0, props.maxLength))
                        }}
                        onBlur={onBlur}
                        resizable={false}
                        defaultValue={undefined}
                        type={"number"}
                        styles={textFieldStyle}
                        borderless
                        tabIndex={props.tabIndex}
                    />
                    {customError &&
                        <div style={{fontWeight: "400", paddingTop: "2px"}} className={SingleSelectStyles.error}>
                            {customError}
                        </div>
                    }
                </>
            )}
        />
    );
};