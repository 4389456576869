import React, { useState } from 'react'
import { IStatusMessage } from '../../../../interfaces/IApp'
import { Checkbox, DefaultButton, DialogFooter, PrimaryButton, TextField } from '@fluentui/react'
import StatusMessage from '../../../../share/StatusMessage'
import { FormAssemblyActions, IFormAssemblyRule, IRuleElement } from '../../../../interfaces/IFormAssembly'

type FormAssemblyModalProps = {
    close: () => void
    handleFormAssembly: (option: FormAssemblyActions, rule: IFormAssemblyRule) => IStatusMessage
}
export default function FormAssemblyModal({ close, handleFormAssembly }: FormAssemblyModalProps) {

    const [message, setMessage] = useState<IStatusMessage | undefined>(undefined)
    const [ruleName, setRuleName] = useState('')
    const [adminOnly, setAdminOnly] = useState(false)

    const handleChange = (event: React.FormEvent, newValue?: string | undefined) => {
        setRuleName(newValue ?? '')
    }
    const handleShowMessage = (message: IStatusMessage | undefined) => {
    }
    const handleSave = () => {
        const status = handleFormAssembly('CREATE', {
            ruleName,
            conditions: [],
            actions: [],
            adminOnly
        })
        if (status.Type === 'error') {
            return setMessage(status)
        }
        close()
    }
    return (
        <>
            {message ? <StatusMessage setShowMessage={handleShowMessage} status={message.Type} hasTimer={message.HasTimeOut}>
                <>{message.Message}</>
            </StatusMessage> : null}
            <TextField
                label="Rule Name"
                required
                maxLength={50}
                onChange={handleChange}
                placeholder="Rule Name."
                id="ruleName"
                title=""
                value={ruleName}
            />
            <Checkbox
                className="mt-2"
                name={'isAdmin'}
                label="Only for Admininstrators"
                title="Only for Admininstrators"
                onChange={(e, checked) => {
                    setAdminOnly(checked ?? false)
                }}
                checked={adminOnly}
            />
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DialogFooter>
                        <PrimaryButton id="btnCreateUpdate" onClick={handleSave} type="submit" text="Save" disabled={ruleName === ''} title="Save" />
                        <DefaultButton id="btnCancel" onClick={close} text={'Cancel'} title={'Cancel'} />
                    </DialogFooter>
                </div>
            </div>
        </>
    )
}
