import { ActionType, IAction, IFormAssemblyRule, TabActionType } from "../../../../interfaces/IFormAssembly"
import { PageItem } from "../../../../share/CustomPaginate"
import { ILayout } from "../../CustomTemplate"
import { evaluateFormAssembly } from "../../CustomTemplate/formAssembly/utils"
import { InputType } from "../../Validations"

export enum AssemblyActions {
    VALIDATE_RULES = 'VALIDATE_RULES',
    VALIDATE_TAB_RULES = 'VALIDATE_TAB_RULES',
    CONDITION_VALID = 'CONDITION_VALID',
    RUN_ACTIONS = 'RUN_ACTIONS',
    RUN_TAB_ACTIONS = 'RUN_TAB_ACTIONS',
    RESTART_VALUE = 'RESTART_VALUE'
}
type AssemblyAction = {
    type: AssemblyActions.VALIDATE_RULES,
    payload: {
        isAdmin: boolean,
        values: any
    }
} | {

    type: AssemblyActions.VALIDATE_TAB_RULES,
    payload: {
        isAdmin: boolean,
        values: any
    }
} | {
    type: AssemblyActions.RUN_ACTIONS,
    payload: {
        type: InputType
        value: string
    }
} | {
    type: AssemblyActions.RUN_TAB_ACTIONS
} | {
    type: AssemblyActions.RESTART_VALUE
}
export type AssemblyState = {
    id: string
    formRules: IFormAssemblyRule[]
    actionsToRun: IAction[]
    watchConditions: string[]
    controlStatus: ActionType | TabActionType
    isRuleFired: boolean
    value: string
    layouts: ILayout[],

} & ({
    type: 'field',
} | {
    type: 'tab',
    pageItems: PageItem[],
    pages: number
})

export type AssemblyTabState = AssemblyState & {
    type: 'tab',
    pageItems: PageItem[],
    pages: number

}
export const formAssemblyReducer = (state: AssemblyState, action: AssemblyAction): AssemblyState => {
    switch (action.type) {
        case AssemblyActions.VALIDATE_RULES:
            return validateRules(state, action)
        case AssemblyActions.VALIDATE_TAB_RULES:
            return validateRules(state, action)
        case AssemblyActions.RUN_ACTIONS: {
            //console.log('REDUCER RUN_ACTIONS')
            let tempStatus = { id: state.id, value: state.value, status: state.controlStatus, isRuleFired: false }
            state.actionsToRun.forEach((actionToRun) => {

                if (actionToRun && actionToRun.type === 'field') {
                    tempStatus.status = actionToRun.actionType
                    if (actionToRun.actionType === ActionType.Value) {
                        //console.log('id', state.id, 'type', action.payload.type)
                        tempStatus.value = actionToRun.fieldValue ?? ''
                        tempStatus.isRuleFired = true
                    }
                    //tempStatus.value = getResetValueByType(tempStatus.value, action.payload.type, actionToRun.actionType)

                    tempStatus.id = actionToRun.value ?? ''
                }
            })
            return { ...state, controlStatus: tempStatus.status, id: tempStatus.id, value: tempStatus.value, isRuleFired: tempStatus.isRuleFired }
        }
        case AssemblyActions.RUN_TAB_ACTIONS: {
            //console.log('REDUCER RUN_ACTIONS')
            if (state.type !== 'tab')
                return state
            let tempStatus = { status: state.controlStatus, isRuleFired: false }
            let pageItems = [...state.pageItems]
            state.actionsToRun.forEach((actionToRun) => {
                if (actionToRun && actionToRun.type === 'tab') {
                    tempStatus.status = actionToRun.actionType
                    pageItems = state.pageItems?.map((item) => {
                        if (item.index === Number(actionToRun.value)) {
                            return { ...item, disabled: actionToRun.actionType === TabActionType.Hide }
                        }
                        return { ...item }
                    })
                }
            })
            console.log('pageItems', pageItems)
            return { ...state, controlStatus: tempStatus.status, isRuleFired: tempStatus.isRuleFired, pageItems }
        }
        case AssemblyActions.RESTART_VALUE:
            return { ...state, isRuleFired: false }
        default:
            return state

    }
}
export const getDefaultPages = (pages: number) => {
    return Array.from(Array(pages).keys()).map((page) => {
        return {
            index: page,
            name: `${page + 1}`
        }
    })
}
const validateRules = (state: AssemblyState, action: AssemblyAction) => {
    if (action.type === AssemblyActions.VALIDATE_RULES || action.type === AssemblyActions.VALIDATE_TAB_RULES) {
        if (state.formRules.length === 0)
            return { ...state }
        const rulesToEvaluate = state.formRules.filter(rule => rule.conditions.find(c => state.watchConditions?.find(w => w === c.field)))
        const actionsToRun = evaluateFormAssembly(rulesToEvaluate, action.payload.isAdmin, action.payload.values, state.layouts)
        if (actionsToRun.length > 0) {
            if (state.type === 'field')
                return { ...state, actionsToRun: actionsToRun.filter(a => a.type === 'field' && a.value === state.id) }
            if (state.type === 'tab')
                return { ...state, actionsToRun: actionsToRun.filter(a => a.type === 'tab') }
        }
        //if (state.isRuleFired) {
        //    return { ...state, actionsToRun: [], isRuleFired: false, controlStatus: ActionType.Show }
        //}
        if (state.type === 'tab')
            return { ...state, actionsToRun: [], controlStatus: ActionType.Show, pageItems: getDefaultPages(state.pages) }
        return ({
            ...state, actionsToRun: [], controlStatus: ActionType.Show
        })
    }
    return state
}
