import { Modal, IconButton, IIconProps } from '@fluentui/react'
import React, { useCallback, useMemo, useState } from 'react'
import RGL, { WidthProvider } from "react-grid-layout";
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { CustomForm } from './CustomForm/CustomForm';
import Helper from '../../Helpers/Helper';
import { useFormAssembly } from './CustomTemplate/hooks/useFormAssembly';
import { IFormAssemblyRule } from '../../interfaces/IFormAssembly';

const theme = getTheme();

type WizardPreviewProps = {
    Username: string
    templateId: string
    templateName: string
    layouts: any
    modalPreviewOpen: boolean
    togglePreview: any
    pageCount: any
    released: any
    pageSelected: any
}
const WizardPreview = ({ modalPreviewOpen, templateId, Username, layouts, pageSelected, templateName, pageCount, released, togglePreview }: WizardPreviewProps) => {

    const [contentPreview, setContentPreview] = useState<JSX.Element[]>();
    const { formRules } = useFormAssembly()

    const mappedFormRules: () => IFormAssemblyRule[] = useCallback(() => {
        return formRules.map((rule) => {
            const conditions = rule.conditions.map((condition) => {
                return { ...condition, field: `preview__${condition.field}` }
            })
            const actions = rule.actions.map((action) => {
                if (action.type === 'field')
                    return { ...action, value: `preview__${action.value}` }
                return action
            })
            return { ...rule, conditions, actions }
        })
    }, [formRules])

    const getWizardLayout = useCallback(() => {
        return {
            TemplateId: templateId,
            Owner: Username,
            Layout: [...layouts],
            Pages: pageCount.toString(),
            formRules: mappedFormRules(),
            Released: released
        }
    }, [Username, mappedFormRules, layouts, pageCount, released, templateId])

    return (

        <CustomForm
            allowDrafts={true}
            draftId={null}
            data={null}
            draftBLOB={null}
            loadDocumentDrafts={() => { }}
            closeDraftModal={() => { }}
            parentId={-1}
            isSubform={false}
            documentId={undefined}
            onBehalf={undefined}

            showCustomForm={modalPreviewOpen}
            toggleCustomForm={togglePreview}
            getWizardLayout={getWizardLayout}
            wizardLayouts={layouts}
            pageSelected={pageSelected}
            isWizardPreview={true}
            siteSelected={Helper.getSiteSelected()}
            accountId={Helper.getDefaultAccount()}
            targetUser={Helper.getUsername()}
            username={Helper.getUsername()}
            sessionKey={Helper.getSessionKey()}
            templateId={templateId}
            templateName={`Preview (${templateName})`}
            update={() => { }}
            isCreator={false}
            isOwner={true}
            isCoOwner={false}
            culture={''}
            ownerName={''}
            ownerUser={''}
            templateCategory={''}
            audienceName={''}
            emailMoniker={''}
            documentCreation={''}
            templateEdit={''}
            defaultDescription={''}
        />
    )
}

export default WizardPreview