import {FC, useEffect} from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import * as React from "react";
import Table from "./Grid/Table";
import { IColumn, IList, IRow } from "./Grid/Models";

export interface IGridListModel {
  id: string;
  columns: IColumn[];
  gridListRows: IList;
  
  locale:string
  getJSXElement: (type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean) => JSX.Element
  onAdd: (form: UseFormReturn<any, any>, data: any) => void;
  onUpdate: (row: IRow, cellIdToEdit: string, form: UseFormReturn<any, any>, data: any) => void;
  onDelete: (row: IRow, form: UseFormReturn<any, any>) => void;
}

export const RHFGridControl: FC<IGridListModel> = (props) => {
 
  const gridForm = useForm<any, any>({
    defaultValues: {},
    
    reValidateMode: "onSubmit",
    mode: "all",
  });

  return (
    <Controller
      name={props.id}
      control={gridForm.control}
      rules={undefined}
      render={() => (
        <Table
          id={props.id}
          columns={props.columns}
          getJSXElement={props.getJSXElement}
          list={props.gridListRows}
          onAdd={props.onAdd}
          locale={props.locale}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
          gridForm={gridForm}
        />
      )}
    />
  );
};