import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { TextField, ITextFieldProps } from "@fluentui/react/lib/TextField";
import { textFieldStyle } from "./Styles";

export const RHFTextBoxControl: FC<ReactHookFormControlProps & ITextFieldProps> = (props) => {

    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <>
                    {
                        <TextField
                            id={props.id}
                            name={fieldName}
                            label={props.label}
                            onRenderLabel={props.onRenderLabel}
                            title={props.title}
                            errorMessage={error && error.message}
                            required={props.required}
                            disabled={props.readOnly}
                            value={value}
                            onChange={(event, newValue) => {
                                props.setValue(props.id, newValue)
                                props.trigger(props.id)
                                onChange(newValue)
                            }}

                            placeholder={props.placeholder}
                            onBlur={onBlur}
                            resizable={false}
                            defaultValue={undefined}
                            borderless
                            styles={textFieldStyle}
                            tabIndex={props.tabIndex}
                            maxLength={props.maxLength}
                        />
                    }
                </>
            )
            }
        />
    );
};