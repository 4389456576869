import { FC } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { TextField, ITextFieldProps } from "@fluentui/react/lib/TextField";
import { textAreaStyle } from "./Styles";

export const RHFTextBoxAreaControl: FC<ReactHookFormControlProps & ITextFieldProps> = (props) => {
    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error }
            }) => (
                <TextField
                    id={props.id}
                    name={fieldName}
                    label={props.label}
                    placeholder={props.placeholder}
                    onRenderLabel={props.onRenderLabel}
                    title={props.title}
                    errorMessage={error && error.message}
                    required={props.required}
                    disabled={props.readOnly || false}
                    value={value}
                    onChange={(event, newValue) => {
                        props.setValue(props.id, newValue)
                        onChange(newValue)
                    }}
                    onBlur={onBlur}
                    resizable={false}
                    defaultValue={undefined}
                    multiline={true}
                    rows={6}
                    styles={textAreaStyle}
                    borderless
                    tabIndex={props.tabIndex}
                    maxLength={props.maxLength}
                />
            )}
        />
    );
};