import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import * as React from "react";
import { ReactHookFormControlProps } from "../Models/ReactHookFormControlProps";
import { FontIcon, Modal } from "@fluentui/react";
import Select, { MenuListProps, OptionProps, SingleValue } from "react-select";
import { SingleSelectStyles, customSelectDropdownStyles } from "./Styles";
import { useSelectSingleControl } from "./hooks/useSelectSingleControl";
import { SelectProps } from "./Interfaces/Select";
import { isMobile } from "react-device-detect";
import MobileSelect from "./MobileSelect";
import { IMyListOption } from "../../../../Helpers/Helper";

export const RHFSelectSingleControl: FC<
    ReactHookFormControlProps & SelectProps
> = (props) => {
    const { options, mapDefaultValue, onChangeDropdown, selectedOption } = useSelectSingleControl({
        id: props.id, options: props.options, defaultValue:props.defaultValue
    });

    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => (
                <>
                    {props.onRenderLabel()}
                    {isMobile ? <MobileSelect
                        required={props.required ?? false}
                        readOnly={props.readOnly ?? false}
                        defaultValue={selectedOption}
                        label={props.label}
                        placeholder={props.placeholder || ""}
                        tabIndex={props.tabIndex}
                        onChange={(newValue) => {
                            onChangeDropdown(
                                newValue as SingleValue<IMyListOption>
                            );
                        }} options={options} name={props.id} /> :
                        <Select
                            id={props.id}
                            options={options}
                            onChange={(newValue, actionMeta) => {
                                onChangeDropdown(
                                    newValue
                                );
                            }}
                            placeholder={props.placeholder || ""}
                            isClearable
                            isSearchable
                            required={props.required}
                            isMulti={false}
                            isDisabled={props.readOnly}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            value={selectedOption}
                            minMenuHeight={200}
                            maxMenuHeight={327}
                            tabIndex={props.tabIndex}
                            styles={customSelectDropdownStyles}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => (
                                    <FontIcon
                                        iconName="ChevronDown"
                                        style={{
                                            paddingRight: "7px",
                                            fontSize: "12px",
                                            color: "black",
                                            fontWeight: "400"
                                        }}
                                    />
                                ),
                                Option
                            }}
                        />
                    }
                    {error && props.getValues(props.id) === undefined ? <div style={{ fontWeight: "400", paddingTop: "2px" }} className={SingleSelectStyles.error}>
                        {error.message}
                    </div> : null}
                </>
            )}
        />
    );
};


export const Option = (props: OptionProps) => {
    const { innerProps, innerRef } = props;
    return (
        <div
            className={SingleSelectStyles.option}
            style={{
                backgroundColor: props.isSelected ? "rgb(237, 235, 233)" : "#ffffff"
            }}
            ref={innerRef}
            {...innerProps}
        >
            <p className={SingleSelectStyles.paragraph}>{props.label}</p>
        </div>
    );
};


