import { DefaultButton, DialogFooter, IDialogFooterStyles, PrimaryButton, Stack, mergeStyleSets } from "@fluentui/react";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TabActionType } from "../../../interfaces/IFormAssembly";
import CustomPaginate, { PageItem } from "../../../share/CustomPaginate";
import { useCustomForm } from "./hooks/useCustomForm";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AssemblyActions, formAssemblyReducer, getDefaultPages } from "./reducers/formAssemblyReducer";
import { useBranding } from "../../../hooks/useBranding";

type FormPaginateProps = {
    pages: number
    currentPage: number
    forcePage: number
    showPagination: boolean
    submitDisable: boolean
    setForcePage: React.Dispatch<React.SetStateAction<number>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    handleCloseModal: () => void
    onSave: (items?: PageItem[] | undefined) => void
    isWizardPreview: boolean
    data: any
}
export default function FormPaginate({ pages, currentPage, forcePage, setForcePage, showPagination, handleCloseModal,
    submitDisable, onSave, isWizardPreview, data,
    setCurrentPage }: FormPaginateProps) {
    const { branding } = useBranding()
    const { getValues } = useFormContext()
    const { t } = useTranslation(["common", "wizard", "preview"]);
    const { layoutsRef, getWatchTabConditions, formRules, layouts, isAdmin } = useCustomForm()
    const watchConditions = getWatchTabConditions()
    const values = useWatch<any>({ name: watchConditions })
    const contentStyles = mergeStyleSets({
        pagination: {
            padding: 0,
            selectors: {
                "ul": {
                    display: "inline-block"
                },
                "li": {
                    fontSize: "12pt",
                    color: branding.theme.palette.themeDarker,
                    display: "inline-block",
                    border: `solid 1px ${branding.theme.palette.themeDarker}`,
                    cursor: "pointer",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    borderCollapse: "collapse",
                    fontWeight: "500"
                }
            }
        },
        active: {
            backgroundColor: branding.theme.palette.themeDarker,
            selectors: {
                "span": {
                    color: branding.theme.palette.white
                }
            }
        }
    })

    const [state, dispatch] = useReducer(formAssemblyReducer, {
        id: '',
        formRules,
        actionsToRun: [],
        watchConditions,
        controlStatus: TabActionType.Show,
        isRuleFired: false,
        value: '',
        layouts: layouts,
        type: 'tab',
        pages,
        pageItems: getDefaultPages(pages)
    })

    useEffect(() => {
        if (state.watchConditions.length > 0) {
            dispatch({
                type: AssemblyActions.VALIDATE_TAB_RULES,
                payload: {
                    isAdmin: isAdmin,
                    values: getValues()
                }
            })
        }
    }, [values, getValues, isAdmin, state.watchConditions.length])

    useEffect(() => {
        if (state.actionsToRun.length > 0) {
            dispatch({
                type: AssemblyActions.RUN_TAB_ACTIONS,
            })
        }
    }, [state.actionsToRun.length])


    const form = useFormContext()

    const onPageClick = (data: any, callback: (result: boolean | number) => void) => {
        if ((data.isPrevious && data.selected === 0) || (data.isNext && data.selected === pages - 1)) {
            callback(false);
        } else {
            validateInputs(data.nextSelectedPage, callback);
        }
    };

    const showSubmit = useMemo(() => {
        if (submitDisable) return true
        if (pages.toString() === currentPage.toString() || (data !== null && data !== "" && data !== undefined)) {
            return true;
        } else {
            if (state.type === 'tab') {
                const lastPage = [...state.pageItems].reverse().find(x => !x.disabled)
                return lastPage?.index === (currentPage - 1)
            }
        }
    }, [currentPage, data, pages, state, submitDisable])

    const validateInputs = (pagina: number, callback: (result: boolean | number) => void) => {
        form.handleSubmit(
            () => {
                setForcePage(pagina);
                setCurrentPage(pagina + 1);
                callback(true); // Success
            },
            (error) => {
                const currentPageKeys = Object.keys(error).filter(
                    (key) =>
                        layoutsRef.current
                            .filter((item) => +item.Page === currentPage && !item.Disabled)
                            .map((control) => control.Id)
                            .includes(key) || key === "_shortcutReference"
                );
                if (currentPageKeys.length > 0) {
                    document.getElementById(currentPageKeys[0])?.focus();
                } else {
                    setForcePage(pagina);
                    setCurrentPage(pagina + 1);
                    callback(pagina); // Error
                }
            }
        )();
    };
    const onSubmit = () => {
        if (state.type === 'tab')
            onSave(state.pageItems)
    }

    return <DialogFooter styles={dialogStyles}>
        <div>
            {showPagination ? (
                <Stack horizontal>
                    <Stack horizontal>
                        <CustomPaginate items={state.type === 'tab' ? state.pageItems : []} onClick={onPageClick} previousLabel={"<<"}
                            nextLabel={">>"} breakLabel={"..."} breakClassName={"break-me"} pageCount={pages}
                            marginPagesDisplayed={2} pageRangeDisplayed={5} containerClassName={contentStyles.pagination}
                            activeClassName={contentStyles.active} pageLinkClassName={"indexTab"} previousLinkClassName={"indexTab"}
                            nextLinkClassName={"indexTab"} forcePage={forcePage} />
                    </Stack>
                </Stack>
            ) : null}
        </div>
        {!isWizardPreview ? (
            <div className={modalFooter.dialogBtns}>
                {showSubmit ? <PrimaryButton id="btnSubmit" onClick={onSubmit} disabled={!showSubmit} tabIndex={501} text={t("Common.Button.Submit", { ns: "common" })} title={t("title.btnSubmit", { ns: "wizard" })} /> : null} :
                {!isMobile && <DefaultButton
                    id="btnCancel"
                    tabIndex={502}
                    onBlur={() => {
                        document.getElementById("help")?.focus();
                    }}
                    onClick={handleCloseModal}
                    text={t(
                        "Common.Button.Cancel",
                        { ns: "common" }
                    )}
                    title={t(
                        "Common.Button.Cancel",
                        { ns: "common" }
                    )}
                />}
            </div>
        ) : (
                <></>
            )}
    </DialogFooter>



}
const dialogStyles: IDialogFooterStyles = {
    actions: {
        display: "flex",
        justifyContent: "space-between",
        position: isMobile ? "relative" : "absolute",
        width: "100%",
        bottom: 0,
        margin: 0,
    },
    actionsRight: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    action: {
        margin: "2px 20px",
    },
};
const modalFooter = mergeStyleSets({
    dialogBtns: {
        display: "flex",
        gap: "8px",
    },
});