import { IDropdownOption } from "@fluentui/react";
import { evaluate } from "mathjs";
import React, { useRef } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { getISODateString } from "../../../../../Helpers/DateUtils";
import { IFormControl } from "../../../../../Helpers/Helper";
import { IFormAssemblyRule } from "../../../../../interfaces/IFormAssembly";
import { ILayout } from "../../../CustomTemplate";
import { InputType } from "../../../Validations";
import { RHFGridControl } from "../RHFGridControl";
import CustomInput from "../utils/CustomInput";
import { CustomElement } from "../utils/ElementList";
import { IRow, ICell, IColumn as GridColumn, IList } from "./Models";

type ControlComponent = {
    layout: ILayout
    form: UseFormReturn<any, any>
    utils: any
    labelVisible: boolean
}

export default function GridControl({ layout, form, utils, labelVisible }: ControlComponent) {

    //const FormulaResults = useRef<{ [key: string]: string }>({});
    //const PreFormulaAdd = useRef<{ [key: string]: any }>({});
    //const layout = useRef({ ..._layout })
    const { gridListRows, setGridListRows, getFormControls }: {
        gridListRows: { [key: string]: IList },
        setGridListRows: React.Dispatch<React.SetStateAction<{ [key: string]: IList }>>,
        getFormControls: (data: any) => IFormControl[]
    } = utils

    const calculateFormula = (item: ILayout, form: UseFormReturn<any, any>, index: string, data: any, prevData: { [key: string]: string }) => {
        //let FormulaResults: { [key: string]: string } = {}
        if ((item.Type === InputType.Currency || item.Type === InputType.Number) && item.Validations && item.Validations.ReadOnly && item.Validations.Formula && item.Validations.Formula.trim().length > 0) {
            var formula = item.Validations.Formula;
            var result;

            const regex = /(\[\$[a-z]\w*])|(\$[a-z]\w*)/gi;
            const found = formula.match(regex);
            if (found && found.length > 0) {
                found.forEach((variable) => {
                    const realNameVariable = variable.startsWith("$") ? variable.substring(1) : variable.slice(2, -1);
                    let fieldvalue: any;

                    const result0 = prevData[realNameVariable];

                    if (result0 != undefined) {
                        fieldvalue = result0;
                    } else if (index.length === 0) {
                        const result = data[realNameVariable];
                        if (result !== undefined) {
                            fieldvalue = typeof result === "object" ? (result as IDropdownOption).text : result;
                        } else {
                            fieldvalue = "0";
                        }
                    } else {
                        const result =
                            data[
                            index.length === 0
                                ? realNameVariable
                                : realNameVariable + index
                            ];
                        if (result !== undefined) {
                            fieldvalue = typeof result === "object" ? (result as IDropdownOption).text : result;
                        } else {
                            fieldvalue = "0";
                        }
                    }
                    // fieldvalue.toString()..replace(/[^\d.]/g, '');
                    formula = formula.replace(variable, fieldvalue.toString().replace(/[^\d.-]/g, ''));
                    formula = formula.replace(",", "");
                });
            }

            try {
                const regex2 = /^[0-9()+\-*/%.\s,\t]+$/;
                const found2 = formula.match(regex2);
                if (found2 === null) result = 0;
                else {
                    result = evaluate(formula);
                    if (result === Infinity) result = 0;
                }
            } catch (e) {
                result = 0;
            }
            prevData[item.Id] = result;

            var resultCurrency = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            })
                .formatToParts(result)
                .filter((p) => p.type != "currency" && p.type != "group") // removes the currency part
                .reduce((s, p) => s + p.value, "") // joins the remaining values
                .trim();
            if (item.Type === InputType.Currency) return resultCurrency.toString();
            else return result.toString();
        } else if (item.Type === InputType.DatePicker) {
            const getValue =
                data[
                index.length === 0 ? item.Id : `${item.Id}${index}`
                ];
            return getISODateString(getValue);
        } else {
            var getValue =
                data[
                index.length === 0 ? item.Id : `${item.Id}${index}`
                ];
            return getValue;
        }
    }
    const updateRowList = (currentRowList: IRow[], currentRowValues: IRow, cellIdToEdit: string, form: UseFormReturn<any, any>, data: any): IRow[] => {
        currentRowList.forEach((item) => {
            if (item.Index === currentRowValues.Index) {
                const prevData = {}
                const gridChilren = layout.Children.map<ICell>((contentControlLayout) => {
                    let currentValObj: any = {};
                    const newItem: ILayout = {
                        ...contentControlLayout,
                        Validations: {
                            ...contentControlLayout.Validations,
                            DefaultValue: data[`${contentControlLayout.Id}-${currentRowValues.Index}`]
                        }
                    }

                    currentValObj[contentControlLayout.Id] = calculateFormula(newItem, form, "-" + currentRowValues.Index, data, prevData) ?? "";

                    return {
                        Id: contentControlLayout.Id,
                        Value: getFormControls(currentValObj)[0].Value,
                        Type: contentControlLayout.Type,
                    } as unknown as ICell
                })

                item.Cells = gridChilren
            }
        })
        return currentRowList;
    }

    return (
        <>
            <RHFGridControl
                id={layout.Id}
                gridListRows={utils.gridListRows[layout.Id]}
                locale={utils.locale.current}
                columns={layout.Children.map<GridColumn>((item) => {
                    return {
                        Id: item.Id,
                        Label: item.Label,
                        Type: item.Type.toString(),
                        Value: item.Validations.DefaultValue,
                        Validations: item.Validations,
                    };
                })}
                onAdd={(form: UseFormReturn<any, any>, data: any) => {
                    //console.log('onAdd', gridListRows)
                    Object.keys(data).forEach(function (key, index) {
                        if (data[key] === "cleared") data[key] = undefined;
                    });
                    //FormulaResults.current = {};
                    //PreFormulaAdd.current = {};

                    //PreFormulaAdd.current = data;


                    setGridListRows((gridListRows) => {
                        let tempGridListRows: { [key: string]: IList } = Object.assign({}, gridListRows);

                        let tempRow: IRow = {
                            Cells: [],
                            Index: tempGridListRows[layout.Id].Rows.length.toString(),
                        };
                        const prevData = {}
                        layout.Children.forEach((item) => {
                            let currentValue: any = {};

                            currentValue[item.Id] = calculateFormula(item, form, "", data, prevData) ?? "";
                            utils.getFormControls(currentValue);
                            tempRow.Cells.push({
                                Id: `${item.Id}`,
                                Type: item.Type.toString(),
                                Value: utils.getFormControls(currentValue)[0].Value,
                            });
                            form.resetField(item.Id);
                        });

                        tempGridListRows[layout.Id].Rows.push(tempRow);
                        return tempGridListRows
                    });
                }}
                getJSXElement={(type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean): JSX.Element => {

                    let currentChildren = layout.Children.find((item) => item.Id === id);
                    if (!currentChildren)
                        return <>{value}</>
                    const defaultValue = rowIndex === '' ? currentChildren.Validations.DefaultValue : (value === undefined ? "" : value)
                    //console.log('defValue', defaultValue, 'rowIndex', rowIndex)
                    const newItem: ILayout = {
                        ...currentChildren,
                        Id: rowIndex.length > 0 ? `${currentChildren!.Id}-${rowIndex}` : currentChildren.Id,
                        Validations: { ...currentChildren.Validations, DefaultValue: defaultValue, }
                    }
                    //currentChildren.Validations.DefaultValue = value === undefined ? "" : value;
                    //const originalChildrenId = currentChildren!.Id;
                    //currentChildren.Id = rowIndex.length > 0 ? `${currentChildren!.Id}-${rowIndex}` : originalChildrenId;

                    utils.getValidations(newItem, form);
                    let tempElement = <FormProvider {...form} >
                        <CustomInput layout={newItem} readOnly={newItem.Validations.ReadOnly} form={form} utils={utils} labelVisible={true} />
                    </FormProvider>
                    //if(currentChildren)
                    //currentChildren!.Id = originalChildrenId;
                    form.setValue(newItem.Id, newItem.Validations.DefaultValue)
                    //elementList[currentChildren!.Type]();
                    return tempElement;
                }}
                onUpdate={(row: IRow, cellIdToEdit: string, form: UseFormReturn<any, any>, data: any) => {
                    //FormulaResults.current = {};
                    let tempGridListRows: { [key: string]: IList } = Object.assign({}, gridListRows);
                    let updatedRowList = updateRowList(tempGridListRows[layout.Id].Rows, row, cellIdToEdit, form, data);
                    tempGridListRows[layout.Id].Rows = updatedRowList;
                    setGridListRows((currentValue) => Object.assign({}, currentValue, tempGridListRows));
                    tempGridListRows[layout.Id].Rows.forEach((r) => {
                        if (cellIdToEdit === "") {
                            r.Cells.forEach((c) => {
                                form.unregister(`${c.Id}-${r.Index}`);
                            });
                        } else {
                            form.unregister(`${cellIdToEdit}-${r.Index}`);
                        }
                    });
                }}
                onDelete={(row: IRow, form: UseFormReturn<any, any>) => {
                    let tempGridListRows: { [key: string]: IList } = Object.assign({}, gridListRows);
                    let updatedRowList = updateRowList(tempGridListRows[layout.Id].Rows, row, "", form, {});
                    tempGridListRows[layout.Id].Rows = updatedRowList.filter((item: IRow) => item.Index !== row.Index);
                    setGridListRows(tempGridListRows);
                }}
            />
        </>
    )

}