import { Checkbox, IStyleFunctionOrObject, IToggleStyleProps, IToggleStyles, Icon, Toggle, getTheme, mergeStyleSets } from '@fluentui/react'
import { i } from 'mathjs'
import React, { useEffect, useState } from 'react'
import { InputType, getTypeName, FORM_ASSEMBLY_EXCLUDED_TYPES } from '../Validations'
import { useFormAssembly } from './hooks/useFormAssembly';
import { ILayout } from '../CustomTemplate';
const theme = getTheme();

type Status = keyof typeof statusStyles
type WizardLayoutItemProps = {
    control: ILayout
    onChangeBlockCC: (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => void
    onTakeItem: (ev: React.MouseEvent<HTMLElement>) => void
    isOpen: boolean
    setIsOpen: Function
    status?: Status
}
const statusStyles = mergeStyleSets({
    default: {
        backgroundColor: "rgb(226, 227, 229)!important"
    },
    condition: {
        backgroundColor: "#c9efaf!important"
    },
    action: {
        backgroundColor: "#ffd3d3!important"
    },
    isOpen: {
        filter: "brightness(80%)"
    }

})

export default function WizardLayoutItem({ onChangeBlockCC, onTakeItem, status: _status = 'default', isOpen, setIsOpen, control }: WizardLayoutItemProps) {

    const { editMode, addFormAssemblyCC } = useFormAssembly()
    const [status, setStatus] = useState<Status>(_status)
    const [isSelectedForRule, setIsSelectedForRule] = useState(editMode?.contentControls.find(x => x.Id === control.Id) !== undefined)
    const toggleStyle: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
        root: {},
        pill: { height: 10, width: 30, },
    };
    const handleSelectForRule = (ev?: any, isChecked?: boolean) => {
        addFormAssemblyCC(control, !!isChecked)
        setIsSelectedForRule(!!isChecked)
    }
    const showCheckbox = (editMode?.type && editMode?.type !== 'RULES_CONDITION') || (editMode?.type === 'RULES_CONDITION' && !FORM_ASSEMBLY_EXCLUDED_TYPES.includes(control.Type))

    useEffect(() => {
        if (editMode.type === 'RULE_PREVIEW') {
            const isInCondition = editMode.rule?.conditions.find(condition => condition.field === control.Id)
            if (isInCondition) {
                return setStatus('condition')
            }
            const isInAction = editMode.rule?.actions.find(action => action.type === 'field' && action.value === control.Id)
            if (isInAction) {
                return setStatus('action')
            }
            return
        }
        setStatus('default')
        setIsSelectedForRule(editMode?.contentControls.find(x => x.Id === control.Id) !== undefined)
    }, [control.Id, editMode])

    return (
        <div id={`I${control.Id}`} className={`divGridItem ${statusStyles[status]} ${isOpen ? statusStyles.isOpen : ''}`}>
            <div className={toolboxStyles.header}>
                <div className={toolboxStyles.headerLeft}>
                    {showCheckbox ? <Checkbox onChange={handleSelectForRule}
                        checked={isSelectedForRule} /> : null}
                    <Toggle
                        checked={control.Layout.static}
                        id={`B${control.Id}`}
                        styles={toggleStyle}
                        onChange={onChangeBlockCC}
                    />

                    <Icon
                        styles={iconButtonWithoutHoverStyles}
                        iconName={!control.Layout.static ? "Unlock" : "Lock"}
                        title={
                            !control.Layout.static
                                ? "Unlocked item"
                                : "Locked item"
                        }
                    />
                </div>
                {control.Type === InputType.SectionHeader && <ContentGridItem control={control} setIsOpen={setIsOpen} />}
                <Icon
                    styles={iconButtonStyles}
                    iconName={"Cancel"}
                    id={control.Id}
                    onClick={onTakeItem}
                    title="Remove item"
                />
            </div>

            { control.Type !== InputType.SectionHeader && <ContentGridItem control={control} setIsOpen={setIsOpen} />}
            <div className={toolboxStyles.footer}>
                {getTypeName(control.Type)}
                <em>{control.manualTag ? " (Manual tag)" : ""}</em>
            </div>
        </div>
    )
}

const ContentGridItem = ({ control, setIsOpen }: { control: ILayout, setIsOpen: Function }) => {
    return <div className={toolboxStyles.content}
        style={{
            width: control.Type === InputType.SectionHeader ? "50%" : "100%",
        }}
        title={control.Label}
        id={`L${control.Id}`}
        onClick={(event) => {
            console.log('openProps', event.currentTarget.id)
            setIsOpen(event.currentTarget.id)
        }}
    >
        <div
            style={{
                cursor: "pointer",
                marginLeft:
                    control.Type === InputType.SectionHeader
                        ? "10px"
                        : undefined,
                marginRight: "5px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            }}
        >
            {control.Validations.Required ? (
                <span style={{ color: "#973434" }}>*</span>
            ) : null}
            {control.Label}
            {control.Validations.ReadOnly ? (
                <span
                    style={{
                        color: "#973434",
                        paddingLeft: 8,
                    }}
                >
                    (Read-only)
                </span>
            ) : null}
        </div>
        <Icon
            styles={iconButtonItemStyles}
            iconName="Settings"
            title="View properties"
            id={`C${control.Id}`}
        />
    </div>
}
const toolboxStyles = mergeStyleSets({
    header: {
        display: "flex",
        flexDirection: "row",
        flex: '0 0 auto'
    },
    headerLeft: {
        display: "flex",
    },
    content: {
        display: "flex",
        justifyContent: "center",
        flex: '1 1 auto',
        alignItems: 'center'
    },
    footer: {
        display: "flex",
        flex: '0 0 auto',
        fontSize: '9px'
    }

})
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const iconButtonItemStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginTop: "3px",
        marginRight: "1px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const iconButtonWithoutHoverStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};