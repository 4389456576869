import { IDropdownOption } from '@fluentui/react'
import React, { useState } from 'react'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { IFormControl } from '../../../../Helpers/Helper'
import { IAction, IFormAssemblyRule } from '../../../../interfaces/IFormAssembly'
import { ILayout } from '../../CustomTemplate'
type User = {
    isOwner: boolean
    isCoOwner: boolean
    username: string
}

type ContextProps = {
    layouts: ILayout[]
    isAdmin:boolean
    setLayouts:React.Dispatch<React.SetStateAction<ILayout[]>>
    templateId: number
    user: User
    data: any
    isSubform: boolean
    documentValues: IFormControl[]
    applicationResponse: any[] | undefined
    integrationDropDownLists: { [key: string]: IDropdownOption[] }
    customDropDownLists: { [key: string]: IDropdownOption[] }
    formRules: IFormAssemblyRule[]
    setFormRules: React.Dispatch<React.SetStateAction<IFormAssemblyRule[]>>

}
type CustomFormProviderProps = {
    actionsToRun: IAction[]
    setActionsToRun: React.Dispatch<React.SetStateAction<IAction[]>>
} & ContextProps
export const context = React.createContext<CustomFormProviderProps>({} as CustomFormProviderProps)

const CustomFormProvider: FC<ContextProps> = (props) => {
    const [actionsToRun, setActionsToRun] = useState<IAction[]>([])
    return (
        <context.Provider value={{
            ...props,
            actionsToRun, setActionsToRun
        }}>
            {props.children}
        </context.Provider>
    )
}
export default CustomFormProvider