import React, { useEffect, useReducer, useState } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form';
import { ILayout, Validation } from '../../../CustomTemplate';
import { ActionType, IFormAssemblyRule } from '../../../../../interfaces/IFormAssembly';
import CustomInput from './CustomInput';
import { useCustomForm } from '../../hooks/useCustomForm';
import { AssemblyActions, formAssemblyReducer } from '../../reducers/formAssemblyReducer';

type ControlComponent = {
    layout: ILayout
    form: UseFormReturn<any, any>
    utils: any
    labelVisible: boolean
    formRules?: IFormAssemblyRule[]
}


export const CustomElement = ({ layout, form, utils }: ControlComponent) => {

    const { getValues, setValue, trigger, clearErrors } = form
    const { Id: id } = layout
    const { getWatchConditions, userIsAdmin, formRules, layouts } = useCustomForm()
    const watchConditions = getWatchConditions(layout.Id)

    const data = useWatch<any>({ name: getWatchConditions(id) })
    const readOnly = layout.Validations.ReadOnly
    const [validations, setValidations] = useState<Validation>(layout.Validations)
    const [fieldValue, setFieldValue] = useState<string | undefined>(undefined)
    const [state, dispatch] = useReducer(formAssemblyReducer, {
        id,
        formRules,
        actionsToRun: [],
        watchConditions,
        controlStatus: ActionType.Show,
        isRuleFired: false,
        value: form.getValues(id),
        layouts: layouts,
        type: 'field'
    })


    useEffect(() => {
        if (state.watchConditions.length > 0) {
            dispatch({
                type: AssemblyActions.VALIDATE_RULES,
                payload: {
                    isAdmin: userIsAdmin,
                    values: getValues()
                }
            })
        }
    }, [data, getValues, userIsAdmin, state.watchConditions.length])



    useEffect(() => {
        if (state.actionsToRun.length > 0) {

            dispatch({
                type: AssemblyActions.RUN_ACTIONS,
                payload: {

                    type: layout.Type,
                    value: state.value
                }
            })
            setValidations(prev => ({ ...prev, Required: false }))
            clearErrors(state.id)
        }
    }, [layout.Type, state.actionsToRun.length, state.id, state.value])

    useEffect(() => {
        if (state.isRuleFired) {
            setValue(state.id, state.value)
            setFieldValue(state.value)
            trigger(state.id)
            dispatch({ type: AssemblyActions.RESTART_VALUE })
        }
    }, [setValue, state.id, state.isRuleFired, state.value, trigger])

    return (
        <>
            {
                state.controlStatus !== ActionType.Hide &&
                <div className='input-form'>
                    <CustomInput layout={layout} form={form} utils={utils} status={state.controlStatus}
                        labelVisible={true} isRequired={validations.Required} fieldValue={fieldValue}
                        readOnly={state.controlStatus === ActionType.Lock ? true : readOnly} />
                </div>
            }
        </>
    )

}