import { IconButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import Cell from "./Cell";
import { ICell, IColumn, IRow } from "./Models";
import { useTranslation } from "react-i18next";

export enum StateType {
    EditRow = 1,
    Read = 3,
}

export interface IRowModel {
    columns: IColumn[];
    locale: string
    getJSXElement: (type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean) => JSX.Element
    row: IRow;
    onUpdate: (cellIdToEdit: string, data: any) => void
    onDelete: () => void
    gridForm: UseFormReturn<any, any>
}

export interface IColumnModel {
    id: string;
    name: string;
}

const Row = (props: IRowModel) => {
    const [rowState, setRowState] = useState<StateType>(StateType.Read)
    const [cellIdToEdit, setCellIdToEdit] = useState<string>("")
    const { t } = useTranslation(['common', 'wizard'])

    const handleEditClick = () => {
        setRowState(StateType.EditRow)
    }

    const handleUpdateClick = () => {
        props.gridForm.handleSubmit(
            (data) => {

                setRowState(StateType.Read)
                props.onUpdate(cellIdToEdit, data);
                setCellIdToEdit("")
            },
            (error) => {

                let headerKeys = props.row.Cells.map(cell => cell.Id)
                let values = props.gridForm.getValues()
                headerKeys.forEach(column => {
                    delete values[column]
                })
                delete values[headerKeys[0].split('_')[1]]
                let valuesKeys = Object.keys(values)
                let invalidFields = headerKeys.filter(x => valuesKeys.includes(x))
                let errorKeys = Object.keys(error)
                let invalidCells = valuesKeys.filter(x => errorKeys.includes(x))

                if (invalidFields.length === 0 && invalidCells.length === 0) {
                    setRowState(StateType.Read)
                    props.onUpdate(cellIdToEdit, values);
                    setCellIdToEdit("")
                }
            })()
    }
    const handleDeleteClick = () => props.onDelete();

    return (
        <tr style={{ verticalAlign: "top" }}>
            {props.columns.map((column) => {
                let currentCell = props.row.Cells.find((item: ICell) => item.Id === `${column.Id}`)!
                if (currentCell === undefined) {
                    currentCell = { Id: column.Id, Type: column.Type, Value: "" }
                }

                const getLabelVisibility = () => {
                    if (cellIdToEdit.length > 0)
                        if (cellIdToEdit === currentCell.Id)
                            return true
                        else
                            return false;
                    else if (rowState === StateType.EditRow && cellIdToEdit.length == 0)
                        return true;
                    else
                        return false;
                }

                const handleGetJSXElement = (id:string): JSX.Element => {
                    if (cellIdToEdit != "") {
                        props.row.Cells.forEach(cell => {

                            props.gridForm.setValue(`${cell.Id}-${props.row.Index}`, cell.Value)
                        })
                    }
                    return props.getJSXElement(column.Type, id, currentCell.Value, props.row.Index, props.gridForm, getLabelVisibility())
                }

                return (
                    <td key={`${column.Id}-${props.row.Index}`}>
                        <Cell
                            row={props.row}
                            column={column}
                            locale={props.locale}
                            cell={currentCell}
                            rowState={rowState === StateType.EditRow ? StateType.EditRow : (cellIdToEdit.length > 0 && `${cellIdToEdit}-${props.row.Index}` === `${currentCell.Id}-${props.row.Index}`) ? StateType.EditRow : StateType.Read}
                            getJSXElement={handleGetJSXElement}
                            setCellIdToEdit={setCellIdToEdit}
                            gridForm={props.gridForm}
                        />
                    </td>
                );
            })}
            <td>
                {(rowState === StateType.Read && cellIdToEdit.length === 0) ? <IconButton onClick={handleEditClick} iconProps={{ iconName: 'Edit' }} title={t('title.btnEdit', { ns: 'wizard' })} ariaLabel={t('ariaLabel.btnEdit', { ns: 'wizard' })} /> : null}
                {(rowState === StateType.EditRow || cellIdToEdit.length > 0) ? <IconButton onClick={handleUpdateClick} iconProps={{ iconName: 'Save' }} title={t('title.btnUpdate', { ns: 'wizard' })} ariaLabel={t('ariaLabel.btnUpdate', { ns: 'wizard' })} /> : null}
                <IconButton onClick={handleDeleteClick} iconProps={{ iconName: 'Delete' }} title={t('title.btnDelete', { ns: 'wizard' })} ariaLabel={t('ariaLabel.btnDelete', { ns: 'wizard' })} />
            </td>
        </tr>
    );
};

export default Row