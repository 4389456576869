import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {ITextFieldProps, Stack} from "@fluentui/react";
import {ReactHookFormControlProps} from "../Models/ReactHookFormControlProps";
import CurrencyInput from "react-currency-input-field";
import {SingleSelectStyles} from "./Styles";
import {currencyOptions} from "../../CustomTemplate.data";

export const RHFCurrencyControl: FC<ReactHookFormControlProps & ITextFieldProps> = (props) => {

    const defaultCurrency = "USD"
    const defaultCulture = "en-US"
    const [customError, setCustomError] = useState<string | undefined>(undefined)

    const [currencyValue, setCurrencyValue] = useState<string>(() => {
        if (Number.isNaN(Number(props.getValues(props.id)))) {
            setCustomError(props.getValues(props.id))
            return  "0"
        }
        else return  props.getValues(props.id)}
    );
    const [decimalSeparator, setDecimalSeparator] = useState<string | undefined>(undefined)
    const [groupSeparator, setGroupSeparator] = useState<string | undefined>(undefined)
    const [decimalLimit, setDecimalLimit] = useState<number | undefined>(undefined)

    useEffect(() => {
        setDecimalSeparator(undefined)
        setGroupSeparator(undefined)
        setDecimalLimit(undefined)

        if (props.currency !== null
            && props.currency !== undefined
            && props.currency.length > 0) {

            const result = currencyOptions().filter((item) => item.key === props.currency);
            if (Array.isArray(result)) {
                if(props.id === 'LotNo3c') {
                    console.log("currencyOptions")
                    console.log(result)
                }
                setDecimalSeparator(result[0].data.decimalSeparator)
                setGroupSeparator(result[0].data.groupSeparator)
                setDecimalLimit(result[0].data.decimal)
            }
        }

    }, [props.id])

    return (
        <Controller
            name={props.id}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}

            render={({
                         field,
                         fieldState: {error}
                     }) => (

                <>
                    <Stack className={"ms-TextField-fieldGroup"}>
                        {props.onRenderLabel!(props)}

                        <CurrencyInput
                            id={props.id}
                            name={field.name}
                            placeholder={props.placeholder}
                            title={props.title}
                            disabled={props.readOnly ? props.readOnly : false}
                            tabIndex={props.tabIndex}

                            intlConfig={{
                                locale: props.culture !== null && props.culture !== undefined && props.culture.length > 0 ? props.culture : defaultCulture,
                                currency: props.currency !== null && props.currency !== undefined && props.currency.length > 0 ? props.currency : defaultCurrency
                            }}

                            decimalSeparator={decimalSeparator}
                            groupSeparator={groupSeparator}
                           
                            className={props.readOnly ? "ms-TextField-field currencyField-disabled" : "ms-TextField-field currencyField"}
                            value={currencyValue}
                            maxLength={props.maxLength}
                            step={0}
                            disableAbbreviations={true}

                            onValueChange={(value, name, values) => {
                                if (values !== null && values !== undefined && values.float !== null && !isNaN(values.float)) {
                                    setCurrencyValue(values.value)
                                    props.setValue(props.id, `${values.float}`)
                                    
                                    if(props.currency === "ALL" ||
                                        props.currency === "JPY" ||
                                        props.currency === "CLP" ||
                                        props.currency === "ISK" ||
                                        props.currency === "IRR" ||
                                        props.currency === "IQD" ||
                                        props.currency === "KRW" ||
                                        props.currency === "LBP" ||
                                        props.currency === "PYG" ||
                                        props.currency === "RSD" ||
                                        props.currency === "SYP" ||
                                        props.currency === "VND" ||
                                        props.currency === "YER"  ) {
                                        props.setValue(props.id, `${values.float}`.replace("0.", ""))

                                    }

                                } else {
                                    setCurrencyValue("0");
                                    props.setValue(props.id, 0)

                                }
                            }}

                        />

                        {error &&
                            <div style={{fontWeight: "400", paddingTop: "2px"}} className={SingleSelectStyles.error}>
                                {error.message}
                            </div>
                        }
                        {customError &&
                            <div style={{fontWeight: "400", paddingTop: "2px"}} className={SingleSelectStyles.error}>
                                {customError}
                            </div>
                        }

                    </Stack>
                </>
            )}
        />
    )

}
