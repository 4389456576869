import React, {useEffect, useState} from 'react';

import '../../Custom.css'
import {confirmDialog, contentStyles, iconButtonStyles} from './style.js'

import {Modal} from '@fluentui/react/lib/Modal';
import {Text} from '@fluentui/react/lib/Text';
import {Separator} from '@fluentui/react/lib/Separator';
import {Dialog, DialogFooter, DialogType} from '@fluentui/react/lib/Dialog';
import {ProgressIndicator} from '@fluentui/react/lib/ProgressIndicator';

import {IDialogContentProps, MessageBar, MessageBarType, Stack} from '@fluentui/react';
import {TextField} from '@fluentui/react/lib/TextField';

import {useTranslation} from 'react-i18next'
import {useForm} from "react-hook-form";
import HelpComponentRelative from '../Help/HelpComponentRelative'

import {ActionButton, DefaultButton, IconButton, PrimaryButton} from '@fluentui/react/lib/Button';
import {IIconProps} from '@fluentui/react/lib/Icon';
import Helper from "../../Helpers/Helper";
import {saveAs} from 'file-saver';
import { Buffer } from 'buffer';


/**
 * Component ExportTemplate render
 * @param props
 */

function ExportTemplate(props: any) {

    const [SuccessMessage, setSuccessMessage] = useState("")
    const [ErrorMessage, setErrorMessage] = useState("")
    const [isHiddenConfirmDialog, setIsHiddenConfirmDialog] = useState(true)
    const [isHiddenSuccessDialog, setIsHiddenSuccessDialog] = useState(true)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [passwordField, setPasswordField] = useState("")
    const [confirmPasswordField, seConfirmPasswordField] = useState("")
    const [txtfileName, setTxtfileName] = useState("")
    const [txtUniqueReference, setTxtUniqueReference] = useState("")
    const [isFormValid, setIsFormValid] = useState(true)
    const [isExporting, setIsExporting] = useState(false)
    const { setError, clearErrors, reset, formState: {errors}} = useForm();
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const {t} = useTranslation(['common', 'exportComponent']);
    const UniqueReferenceIcon: IIconProps = { iconName: 'Copy' };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: confirmDialog,
            dragOptions: undefined,
        }),
        [],
    );
    let dialogSuccessProps: IDialogContentProps = {
        type: DialogType.normal,
        title: t("ExportComponent.SuccessDialog title", {ns: 'exportComponent'}),
        subText: ""
    }
    
    const dialogConfirmProps = {
        type: DialogType.normal,
        title: t("ExportComponent.ConfirmDialog title", {ns: 'exportComponent'}),
        closeButtonAriaLabel: t("Common.Button.Cancel", {ns: 'common'}),
        subText: t("ExportComponent.ConfirmDialog subText", {ns: 'exportComponent'})
    };

    useEffect(() => {
        validate()
    }, [passwordField, confirmPasswordField]);

    /**
     * Validate the form
     */
    const validate = () => {
        clearErrors('passwordField');
        clearErrors('confirmPasswordField');
        setIsFormValid(true)

        if (passwordField.length != 0 || confirmPasswordField.length != 0) {

            if (passwordField.length === 0)
                setError('passwordField', {
                    type: 'custom',
                    message: t("ExportComponent.TextField Password ErrorMessage", {ns: 'exportComponent'})
                });
            else if (passwordField != confirmPasswordField)
                setError('passwordField', {
                    type: 'custom',
                    message: t("ExportComponent.TextField Confirm Password ErrorMessage", {ns: 'exportComponent'})
                });

            if (confirmPasswordField.length === 0)
                setError('confirmPasswordField', {
                    type: 'custom',
                    message: t("ExportComponent.TextField Password ErrorMessage", {ns: 'exportComponent'})
                });
            else if (confirmPasswordField != passwordField)
                setError('confirmPasswordField', {
                    type: 'custom',
                    message: t("ExportComponent.TextField Confirm Password ErrorMessage", {ns: 'exportComponent'})
                });

            if (Object.keys(errors).length > 0) setIsFormValid(false)
        }
    }

    /**
     * Function to hide and clear form fields of the current modal
     * */
    const hideModal = () => {
        setSuccessMessage("")
        setErrorMessage("")
        setPasswordField("")
        seConfirmPasswordField("")
        setShowSuccessMessage(false)
        setIsHiddenConfirmDialog(true)
        setShowErrorMessage(false)
        setIsExporting(false)
        setIsFormValid(true)
        setTxtfileName("")
        setTxtUniqueReference("")
       
        reset()
        props.hideExportTemplate()
    }

    /**
     * Function to Export the Selected Application
     */
    const exportApplication = async () => {
        setIsHiddenConfirmDialog(true)
        setIsExporting(true)
        setErrorMessage("")
        setShowErrorMessage(false)
        setSuccessMessage("")
        setShowSuccessMessage(false)

        await Helper.getExportTemplate(props.templateId, false, passwordField)
            .then((data) => {
                
                let document = Buffer.from(data.result, 'base64').toString('utf8')
                let properties = JSON.parse(document);

                let blob = new Blob([data.result], {
                    type: "text/plain;charset=utf-8"
                });
                
                saveAs(blob, properties.FileName);
                hideModal()
                setTxtfileName(properties.FileName)

                let bag = JSON.parse(data.bag);
                setTxtUniqueReference(window.location.origin + "/app?import=" + bag.UniqueReference)
                setIsHiddenSuccessDialog(false)
                props.notificartions( t("ExportComponent.SuccessDialog notification", {ns: 'exportComponent', Name: properties.FileName }) , "success")


            })
            .catch(errors => {
                setErrorMessage(errors)
                setShowErrorMessage(true)
            })
            .finally(() => {
                    setIsExporting(false)
                }
            )
    }

    const copyUniqueReference = async () => {
        navigator.clipboard.writeText(txtUniqueReference).then(
            () => {},
            () => {
                const input = document.createElement('input');
                input.style.position = 'absolute';
                input.style.left = '-100';
                input.style.zIndex = "-1000";
                document.body.appendChild(input);
                input.value = txtUniqueReference;
                input.focus();
                input.select();

                const result = document.execCommand('copy');

                if (!result) {
                    console.error('Failed to copy text.');
                }
                input.remove();
            }
        )
    }
    
    return (
        /*Modal*/
        <div>

            <Dialog
                hidden={isHiddenConfirmDialog}
                dialogContentProps={dialogConfirmProps}
                modalProps={modalProps}
                onDismiss={() => {
                    setIsHiddenConfirmDialog(true)
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setIsHiddenConfirmDialog(true);
                        exportApplication().then(r => r)
                    }} text={t("Common.Button.Download", {ns: 'common'})}/>
                    <DefaultButton onClick={() => {
                        setIsHiddenConfirmDialog(true)
                    }} text={t("Common.Button.Cancel", {ns: 'common'})}/>
                </DialogFooter>
            </Dialog>


            <Modal
                isOpen={props.showExportTemplate}
                containerClassName={contentStyles.container}
                isBlocking={true}>
                <form>
                    {/*Header*/}
                    <div className={contentStyles.header}>
                        <span>{t("ExportComponent.Component Title", {ns: 'exportComponent'})}</span>
                        <HelpComponentRelative
                            headline={t("ExportComponent.Component Help headline", {ns: 'exportComponent'})}
                            text={t("ExportComponent.Component Help Text", {ns: 'exportComponent'})}
                            right="50px"
                            top="15px"
                            location="importexport"
                        />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            disabled={isExporting}
                            ariaLabel={t("Common.Close", {ns: 'common'})}
                            onClick={hideModal}
                        />
                    </div>
                    {/*End Header*/}

                    <Separator/>

                    {/*Body*/}
                    <div className={contentStyles.body}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                {showErrorMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {ErrorMessage}
                                    </MessageBar>
                                }
                                {showSuccessMessage &&
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        dismissButtonAriaLabel={t("Common.Button.Close", {ns: 'common'})}
                                        truncated={true}
                                        overflowButtonAriaLabel={t("Common.Button.See more", {ns: 'common'})}
                                    >
                                        {SuccessMessage}
                                    </MessageBar>
                                }
                            </div>
                            <br/>
                            <div className="ms-Grid-row">
                                {/*First column*/}
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <Text variant="xLarge">{props.templateName}</Text>
                                    <p>
                                        {t("ExportComponent.Callout Export Description", {ns: 'exportComponent'})}
                                    </p>

                                </div>
                                {/*End First column*/}

                                {/*Second column*/}
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <p className="ms-hiddenLgUp">&nbsp;</p>

                                    <Stack horizontal>
                                        <Stack.Item align="center">
                                            <Text
                                                variant="mediumPlus">{t("ExportComponent.Header Password Title", {ns: 'exportComponent'})}
                                            </Text>
                                        </Stack.Item>
                                    </Stack>
                                    <br/>
                                    <Stack>
                                        <Stack.Item>
                                            <TextField
                                                label={t("ExportComponent.TextField Password", {ns: 'exportComponent'})}
                                                type="password"
                                                disabled={isExporting}
                                                name="passwordField"

                                                //{...register("passwordField")}
                                                onChange={(_, v) => {
                                                    setPasswordField(v!);
                                                }}
                                                errorMessage={errors.passwordField ? errors.passwordField.message!.toString() : ""}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField
                                                label={t("ExportComponent.TextField Confirm Password", {ns: 'exportComponent'})}
                                                type="password"
                                                disabled={isExporting}
                                                name="confirmPasswordField"
                                                //{...register("confirmPasswordField")}
                                                onChange={(_, v) => {
                                                    seConfirmPasswordField(v!);
                                                }}
                                                errorMessage={errors.confirmPasswordField != undefined ? errors.confirmPasswordField.message!.toString() : ""}
                                            />
                                        </Stack.Item>
                                    </Stack>

                                </div>
                                {/*End Second column*/}

                            </div>
                            <br/>
                            {/*Progress Indicator*/}
                            {isExporting &&
                                <div className="ms-Grid-row">
                                    <ProgressIndicator
                                        label={t("ExportComponent.ProgressIndicator Title", {ns: 'exportComponent'})}
                                        description={t("ExportComponent.ProgressIndicator Description", {ns: 'exportComponent'})}/>
                                </div>
                            }
                            {/*End Progress Indicator*/}
                        </div>
                    </div>
                    {/*End Body*/}

                    <Separator/>

                    {/*Footer*/}
                    <div className={contentStyles.footer}>
                        <DialogFooter>
                            <PrimaryButton disabled={isExporting || !isFormValid} onClick={() => {
                                setIsHiddenConfirmDialog(false)
                            }}
                                           text={t("ExportComponent.Button Export", {ns: 'exportComponent'})}
                                           title={t("ExportComponent.Button Export Title", {ns: 'exportComponent'})}/>
                            <DefaultButton onClick={hideModal} text={t("Common.Button.Cancel", {ns: 'common'})}
                                           disabled={isExporting}
                                           title={t("Common.Button.Cancel", {ns: 'common'})}/>
                        </DialogFooter>
                    </div>
                    {/*End Footer*/}
                </form>
            </Modal>
            {/*Success export dialog*/}
            <Dialog
                hidden={isHiddenSuccessDialog}
                dialogContentProps={dialogSuccessProps}
                modalProps={modalProps}
                onDismiss={() => {
                    setIsHiddenSuccessDialog(true)
                }}
            >
                <p style={{whiteSpace: 'pre-line'}}>{t("ExportComponent.SuccessDialog subText", {
                    ns: 'exportComponent', 
                    FileName: txtfileName
                })}
                </p>
                {txtUniqueReference} 
                <p>
                    <ActionButton iconProps={UniqueReferenceIcon} allowDisabledFocus disabled={false} checked={undefined} onClick={()=> {  copyUniqueReference().then() }}>
                        {t("ExportComponent.copyClipboard", {
                            ns: 'exportComponent'
                        })}
                    </ActionButton>
                </p>
                <Separator/>
                <DialogFooter>
                    <PrimaryButton onClick={() => {
                        setIsHiddenSuccessDialog(true);                        
                    }} text={t("Common.Button.Accept", {ns: 'common'})}/>                  
                </DialogFooter>
            </Dialog>
            {/*End Success dialog*/}
        </div>
        /*End Modal*/
    )
}

export default ExportTemplate
