import React from 'react'
import { Stack, ActionButton } from '@fluentui/react';

export default function WizardAddons({ setItemDragged }: any) {

    return (
        <Stack>
            {/*<ActionButton*/}
            {/*  id="btnAddAttachment"*/}
            {/*  onMouseDown={() => { setItemDragged("Attachment") }}*/}
            {/*  draggable={true}*/}
            {/*  unselectable="on"*/}
            {/*  onDragStart={e => e.dataTransfer.setData("text/plain", "hello")}*/}
            {/*  title="Select Attachment to allow upload document from the form wizard." text="Attachment" style={{ maxHeight: 25 }}*/}
            {/*  iconProps={{ iconName: 'Upload' }}*/}
            {/*/>*/}
            <ActionButton
                id="btnAddIntegration"
                onMouseDown={() => {
                    setItemDragged("Integration");
                }}
                draggable={true}
                unselectable="on"
                onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", "hello")
                }
                title="Select integration to place a button to execute an On Click integration."
                text="Add Integration"
                style={{ maxHeight: 25 }}
                iconProps={{ iconName: "WebAppBuilderFragment" }}
            />
            <ActionButton
                id="btnAddLineBreak"
                onMouseDown={() => {
                    setItemDragged("LineBreak");
                }}
                draggable={true}
                unselectable="on"
                onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", "hello")
                }
                title="Select line break to place a horizontal line across the form wizard."
                text="Add Line Break"
                style={{ maxHeight: 25 }}
                iconProps={{ iconName: "SecondaryNav" }}
            />
            <ActionButton
                id="btnAddParagraph"
                onMouseDown={() => {
                    setItemDragged("Paragraph");
                }}
                draggable={true}
                unselectable="on"
                onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", "hello")
                }
                title="Enter static text to provide instructions."
                text="Add Paragraph"
                style={{ maxHeight: 25 }}
                iconProps={{ iconName: "PlainText" }}
            />
            <ActionButton
                id="btnAddSectionHeader"
                onMouseDown={() => {
                    setItemDragged("Section");
                }}
                draggable={true}
                unselectable="on"
                onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", "hello")
                }
                title="Enter text to emphasis headings."
                text="Add Section Header"
                style={{ maxHeight: 25 }}
                iconProps={{ iconName: "InsertTextBox" }}
            />
            {/*<ActionButton*/}
            {/*  id="btnAddHelpLink"*/}
            {/*  onMouseDown={() => { setItemDragged("HelpLink") }}*/}
            {/*  draggable={true}*/}
            {/*  unselectable="on"*/}
            {/*  onDragStart={e => e.dataTransfer.setData("text/plain", "hello")}*/}
            {/*  title="Select HelpLink to create a custom help section inside the form wizard." text="HelpLink" style={{ maxHeight: 25 }}*/}
            {/*  iconProps={{ iconName: 'Link' }}*/}
            {/*/>*/}
        </Stack>
    )
}
