import { IDropdownOption, Separator, IRawStyle } from '@fluentui/react';
import React from 'react'
import { isMobile } from 'react-device-detect';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionType, IFormAssemblyRule, TabActionType } from '../../../../../interfaces/IFormAssembly';
import { ILayout } from '../../../CustomTemplate';
import { InputType } from "../../../Validations";
import { RHFDatePickerControl } from '../DatePicker/RHFDatePickerControl';
import GridControl from '../Grid/GridControl';
import { RHFCheckBoxControl } from '../RHFChekBoxControl';
import { RHFCurrencyControl } from '../RHFCurrencyControl';
import { RHFIntegrationControl } from '../RHFIntegrationControl';
import { RHFMaskControl } from '../RHFMaskControl';
import { RHFNumberControl } from '../RHFNumberControl';
import { RHFRichTextControl } from '../RHFRichTextControl';
import { RHFSelectMultipleControl } from '../RHFSelectMultipleControl';
import { RHFSelectSingleControl } from '../RHFSelectSingleControl';
import { RHFTextBoxAreaControl } from '../RHFTextBoxAreaControl';
import { RHFTextBoxControl } from '../RHFTextBoxControl';
import { RHFUploadRunTimeControl } from '../RHFUploadRunTimeControl';
import {
    getFontStyle,
    getFontWeight,
    getLayoutBackgroundColor,
    getLayoutFontColor,
    getLayoutFontSize,
    getLayoutJustifyContent,
    getTextDecoration,
    onRenderLabel
} from './functions';

type UtilsProps = {
    getLabel: any
    getDefaultValue: any
    imageValues: any
    imageValuesDefault: any
    setImageValues: any
    integrationDropDownLists: { [key: string]: IDropdownOption[]; }
    customDropDownLists: { [key: string]: IDropdownOption[]; }
    templateId: string
    integrationReload: any
    getCustomFormContentControls: any
    username: string
    setErrorMessage: any
    displayIntegration: any
    getValidations: any
    contentLibrary: any
    locale: any

    //getDefaultValue:(layout: ILayout, form: UseFormReturn<any, any> | undefined, defaultValue?: string)=>void
}
type ControlComponent = {
    layout: ILayout
    form: UseFormReturn<any, any>
    utils: UtilsProps
    labelVisible: boolean
    formRules?: IFormAssemblyRule[]
    readOnly?: boolean
    isRequired?: boolean
    isDisabled?: boolean
    fieldValue?: any
    defaultValue?: string | undefined
    status?: ActionType | TabActionType
}


export default function CustomInput({ layout, isRequired, form, utils, labelVisible, readOnly: _readOnly, isDisabled, defaultValue, fieldValue, status }: ControlComponent) {

    const readOnly = status === ActionType.Value ? true : _readOnly;
    const { t } = useTranslation(["common", "wizard", "preview"]);

    const _style:IRawStyle = {
        color: getLayoutFontColor(layout.FontColor),
        backgroundColor: getLayoutBackgroundColor(layout.BackgroundColor),
        textAlign: getLayoutJustifyContent(layout.Align) as 'left' | 'right' | 'center',
        fontStyle: getFontStyle(layout.IsItalic),
        fontWeight: getFontWeight(layout.IsBold),
        textDecoration: getTextDecoration(layout.IsUnderlined),
        fontSize: getLayoutFontSize(layout.FontSize, InputType.SectionHeader) + "px",
    }
    
    switch (layout.Type) {
        case InputType.None: return <></>

        case InputType.Textbox:
            return layout.Validations.Mask.length > 0 ? (
                <RHFMaskControl
                    getErrorMessage={(value: string) => {
                        form.setValue(layout.Id, value);
                    }}
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    required={isRequired}
                    readOnly={readOnly}
                    control={form.control}
                    setValue={form.setValue}
                    formState={form.watch}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    defaultValue={utils.getDefaultValue(layout, form)}
                    mask={layout.Validations.Mask}
                    status={status}
                    maskFormat={{
                        Mask: /[a-zA-Z]/,
                        "9": /[0-9]/,
                        s: /[!@#$%^&*()]/,
                    }}
                />
            ) : (
                    <RHFTextBoxControl
                        id={layout.Id}
                        label={utils.getLabel(layout.AdminOnly, layout.Label)}
                        onRenderLabel={(p) => {
                            return onRenderLabel(p, layout, labelVisible, status, _style);
                        }}
                        title={layout.Tooltip}
                        tabIndex={layout.TabIndex}
                        required={isRequired}
                        readOnly={readOnly}
                        placeholder={layout.Placeholder}
                        disabled={isDisabled}
                        control={form.control}
                        formState={form.watch}
                        setValue={form.setValue}
                        getValues={form.getValues}
                        trigger={form.trigger}
                        status={status}
                        defaultValue={defaultValue}
                        maxLength={layout.Validations.MaxLength}
                    />
                );

        case InputType.TextArea:
            return (
                <RHFTextBoxAreaControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    required={isRequired}
                    readOnly={readOnly}
                    control={form.control}
                    setValue={form.setValue}
                    placeholder={layout.Placeholder}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    status={status}
                    defaultValue={utils.getDefaultValue(layout, form)}
                    maxLength={layout.Validations.MaxLength}
                />
            )

        case InputType.DropDownList:
            return (
                <RHFSelectSingleControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={() => {
                        return onRenderLabel(
                            {
                                required: isRequired,
                                id: layout.Id,
                                label: layout.Label,
                            },
                            layout,
                            labelVisible,
                            status,
                            _style
                        );
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    status={status}
                    options={
                        layout.Integration !== undefined && layout.Integration !== null ?
                            utils.integrationDropDownLists[layout.Integration.Id.toString()]
                            : +layout.ListId > 0
                                ? utils.customDropDownLists[layout.ListId]
                                : layout.ItemList.split(",").map<IDropdownOption>(
                                    (item) => {
                                        return {
                                            key: item?.trim(),
                                            text: item?.trim(),
                                        };
                                    }
                                )
                    }
                    required={isRequired}
                    readOnly={readOnly}
                    placeholder={layout.Placeholder}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    defaultValue={utils.getDefaultValue(layout, undefined, form.getValues(layout.Id))}
                />
            )

        case InputType.CheckBoxList:
            return (
                <RHFSelectMultipleControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={() => {
                        return onRenderLabel(
                            {
                                required: isRequired,
                                id: layout.Id,
                                label: layout.Label,
                            },
                            layout,
                            labelVisible,
                            status,
                            _style
                        );
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    status={status}
                    options={
                        layout.Integration !== undefined && layout.Integration !== null ?
                            utils.integrationDropDownLists[layout.Integration.Id.toString()]
                            : +layout.ListId > 0
                                ? utils.customDropDownLists[layout.ListId]
                                : layout.ItemList.split(",").map<IDropdownOption>(
                                    (item) => {
                                        return {
                                            key: item?.trim(),
                                            text: item?.trim(),
                                        };
                                    }
                                )
                    }
                    required={isRequired}
                    readOnly={readOnly}
                    placeholder={layout.Placeholder}
                    fieldValue={fieldValue}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    defaultValue={utils.getDefaultValue(layout, form)}
                />
            )
        case InputType.RadioButtonList: return <></>

        case InputType.DatePicker:
            return (
                <RHFDatePickerControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    isLabelVisible={labelVisible}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    isRequired={isRequired}
                    placeholder={layout.Placeholder}
                    status={status}
                    readOnly={readOnly}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    locale={utils.locale.current}
                    formatValue={layout.Validations.Regex}
                    formatDate={(date) => {
                        return "";
                    }}
                    defaultValue={utils.getDefaultValue(layout, form)}
                />
            )
        case InputType.Number:
            return (
                <RHFNumberControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    required={isRequired}
                    readOnly={readOnly}
                    status={status}
                    placeholder={layout.Placeholder}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    maxLength={layout.Validations.MaxLength}
                    defaultValue={utils.getDefaultValue(layout, form)}
                />
            )

        case InputType.Currency:
            return (
                <RHFCurrencyControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    currency={layout.Validations.Currency}
                    culture={"en-US"}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    required={isRequired}
                    readOnly={readOnly}
                    status={status}
                    placeholder={layout.Placeholder}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    maxLength={layout.Validations.MaxLength}
                    defaultValue={utils.getDefaultValue(layout, form)}
                />
            )
        case InputType.LineBreak:
            return (
                <Separator
                    styles={{
                        content: {
                            color: getLayoutFontColor(layout.FontColor),
                            fontWeight: getFontWeight(layout.IsBold),
                            fontStyle: getFontStyle(layout.IsItalic),
                            textDecoration: getTextDecoration(layout.IsUnderlined),
                        },
                        root: [
                            {
                                selectors: {
                                    "::before": {
                                        background: getLayoutFontColor(layout.FontColor) + "!important",
                                    },
                                },
                            },
                        ],
                    }}
                >
                    {utils.getDefaultValue(layout, form)}
                </Separator>
            )

        case InputType.SectionHeader:
            return (
                <div
                    style={{
                        display: "block",
                        //right: getLayoutJustifyContent(layout.Align) === "right" ? "8px" : undefined,
                        justifyContent: getLayoutJustifyContent(layout.Align),
                        alignItems: "center",
                        fontSize: getLayoutFontSize(layout.FontSize, InputType.SectionHeader) + "px",
                        bottom: "0px",
                        color: getLayoutFontColor(layout.FontColor),
                        backgroundColor: getLayoutBackgroundColor(layout.BackgroundColor),
                        width: isMobile ? "auto" : "inherit",
                        fontWeight: getFontWeight(layout.IsBold),
                        fontStyle: getFontStyle(layout.IsItalic),
                        textAlign: getLayoutJustifyContent(layout.Align) as 'left' | 'right' | 'center',
                        textDecoration: getTextDecoration(layout.IsUnderlined),
                        paddingLeft: "4px",
                        paddingRight: "4px",
                    }}
                >
                    {layout.Label}
                </div>
            )

        case InputType.Paragraph:
            return isMobile ? (
                <span
                    title={layout.Tooltip}
                    style={{
                        display: 'flex',
                        minHeight: "100px",
                        minWidth: "140px",
                        overflow: "hidden",
                        padding: "0px",
                        lineHeight: 1,
                        fontSize:
                            getLayoutFontSize(
                                layout.FontSize,
                                InputType.Paragraph
                            ) > 22
                                ? "14px"
                                : getLayoutFontSize(
                                    layout.FontSize,
                                    InputType.Paragraph
                                ) + "px",
                        color: getLayoutFontColor(layout.FontColor),
                        fontWeight: getFontWeight(layout.IsBold),
                        fontStyle: getFontStyle(layout.IsItalic),
                        textDecoration: getTextDecoration(layout.IsUnderlined),
                        backgroundColor: getLayoutBackgroundColor(layout.BackgroundColor)
                    }}
                >
                    {layout.TextParagraph}
                </span>
            ) : (
                    <p
                        style={{
                            overflow: "auto",
                            overflowX: "hidden",
                            padding: "3px 10px",
                            margin:"0",
                            lineHeight: 1,
                            fontSize:
                                getLayoutFontSize(
                                    layout.FontSize,
                                    InputType.Paragraph
                                ) > 22
                                    ? "14px"
                                    : getLayoutFontSize(
                                        layout.FontSize,
                                        InputType.Paragraph
                                    ) + "px",
                            color: getLayoutFontColor(layout.FontColor),
                            fontWeight: getFontWeight(layout.IsBold),
                            fontStyle: getFontStyle(layout.IsItalic),
                            textDecoration: getTextDecoration(layout.IsUnderlined),
                            backgroundColor: getLayoutBackgroundColor(layout.BackgroundColor)
                        }}
                    >
                        {layout.TextParagraph}
                    </p>
                )
        case InputType.RichText:
            return (
                <RHFRichTextControl
                    id={layout.Id}
                    label={layout.Label}
                    setRteValue={undefined}
                    rteValue={undefined}
                    placeholder={layout.Placeholder}
                    onRenderLabel={() => {
                        return onRenderLabel(
                            {
                                required: isRequired,
                                id: layout.Id,
                                label: layout.Label
                            },
                            layout,
                            labelVisible,
                            status,
                            _style
                        );
                    }}
                    status={status}
                    contentLibrary={utils.contentLibrary}
                    control={form.control}
                    defaultValue={utils.getDefaultValue(layout, form)}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    readOnly={readOnly}
                    validations={layout.Validations}
                    tabIndex={layout.TabIndex}
                    richValue={fieldValue}
                />
            )

        case InputType.CheckBox:
            return (
                <RHFCheckBoxControl
                    id={layout.Id}
                    label={utils.getLabel(layout.AdminOnly, layout.Label)}
                    onRenderLabel={(p: any) => {
                        return onRenderLabel(p, layout, labelVisible, status, _style);
                    }}
                    status={status}
                    readOnly={readOnly}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    value={form.getValues(layout.Id)}
                    defaultValue={utils.getDefaultValue(layout, form)}
                />
            )

        case InputType.GridList:
            return <GridControl layout={layout} form={form} utils={utils}
                labelVisible={labelVisible}
            />
        case InputType.Attachment: return <></>
        case InputType.Image:
            return (
                <RHFUploadRunTimeControl
                    id={layout.Id}
                    label={layout.Label}
                    onRenderLabel={() => {
                        return onRenderLabel(
                            {
                                required: isRequired,
                                id: layout.Id,
                                label: layout.Label,
                            },
                            layout,
                            labelVisible,
                            undefined,
                            _style
                        );
                    }}
                    title={layout.Tooltip}
                    tabIndex={layout.TabIndex}
                    control={form.control}
                    setValue={form.setValue}
                    readOnly={readOnly}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    defaultValue={utils.getDefaultValue(layout, form)}
                    extraText={layout.TextParagraph}
                    imageValues={utils.imageValues}
                    imageValuesDefault={utils.imageValuesDefault}
                    setImageValues={utils.setImageValues}
                    rules={{
                        validate: (image: any) => {
                            if (typeof image === "string") {
                                return true;
                            }

                            if (image === null && image.size <= 0) {
                                return t("messages.errorImageSize", {
                                    ns: "wizard",
                                }).toString()
                            }

                            var filevalid = false;
                            var sizevalid = 300;
                            var extensions = ["bmp", "tiff", "jpg", "png", "jpeg", "gif", "svg+xml"];
                            var typefile = image?.type;
                            var type = typefile.split("/")[1];
                            filevalid = extensions.includes(type);
                            if (!filevalid) {
                                return t("messages.errorImageTypes", {
                                    ns: "wizard",
                                }).toString()
                            }

                            var fileSize = image.size;
                            var Sizekb = fileSize / 1024;
                            let IsValidSize = Sizekb <= sizevalid && fileSize > 0;
                            if (!IsValidSize) {
                                return t("messages.errorImageMaxSize", {
                                    ns: "wizard",
                                }).toString()
                            }

                            if (filevalid && IsValidSize) {
                                return true;
                            }
                        },
                    }}
                />
            )

        case InputType.HelpLink:
            return <></>;

        case InputType.Integration:
            return (
                <RHFIntegrationControl
                    id={layout.Id}
                    label={layout.Label}
                    inputs={layout.Children}
                    control={form.control}
                    setValue={form.setValue}
                    getValues={form.getValues}
                    trigger={form.trigger}
                    readOnly={readOnly}
                    tabIndex={layout.TabIndex}
                    defaultValue={utils.getDefaultValue(layout, form)}
                    display={utils.displayIntegration(layout)}
                    getJSXElement={(type: string, id: string, value: string, rowIndex: string, form: UseFormReturn<any, any>, labelVisible: boolean): JSX.Element => {
                        console.log('integrations', id, 'value', value)
                        let currentChildren = layout.Children.find((item) => item.Id === id);
                        if (!currentChildren) return <>{value}</>
                        const newItem = { ...currentChildren, Validations: { ...currentChildren?.Validations, DefaultValue: value === undefined ? "" : value } }
                        //currentChildren!.Validations.DefaultValue = value === undefined ? "" : value;
                        //const originalChildrenId = currentChildren!.Id;
                        //currentChildren!.Id = rowIndex.length > 0 ? `${currentChildren!.Id}-${rowIndex}` : originalChildrenId;

                        utils.getValidations(newItem, form);
                        let tempElement = <FormProvider {...form} >
                            <CustomInput layout={newItem} readOnly={newItem.Validations.ReadOnly} form={form} utils={utils} labelVisible={true} />
                        </FormProvider>
                        form.setValue(newItem.Id, newItem.Validations.DefaultValue)
                        //elementList[currentChildren!.Type](currentChildren!, form, labelVisible);
                        //currentChildren!.Id = originalChildrenId;
                        return tempElement;
                    }}
                    templateId={utils.templateId}
                    integration={layout.Integration}
                    integrationReload={utils.integrationReload}
                    getCustomFormContentControls={utils.getCustomFormContentControls}
                    username={utils.username}
                    setCustomFormErrorMessage={utils.setErrorMessage}
                    isPickingList={layout.IsPickingList ?? false}
                    pickingList={layout.PickingList}
                    version={layout.Version}
                />
            )

    }
}