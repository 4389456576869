import React from 'react'
import { useFormAssembly } from "./hooks/useFormAssembly"
import { PrimaryButton, mergeStyleSets, Link, DefaultButton, FontIcon, IIconProps, Icon, IconButton, IButtonStyles } from '@fluentui/react'
import { mode } from 'mathjs'
import { FormRuleEditMode } from '../../../interfaces/IFormAssembly'
type EditModeBarProps = {
    handleFormAssemblySteps: (type: string | undefined) => void
}
export default function EditModeBar({ handleFormAssemblySteps }: EditModeBarProps) {
    const { editMode, resetEditMode, setEditMode } = useFormAssembly()

    const resetSelected = () => {
        setEditMode(mode => {
            return { ...mode, contentControls: [] }
        })
    }
    return (
        <>
            {
                editMode.type && editMode.showBar ?
                    <EditBar editMode={editMode} resetEditMode={resetEditMode}
                        resetSelected={resetSelected} handleFormAssemblySteps={handleFormAssemblySteps} />
                    : editMode?.type === 'RULE_PREVIEW' && <PreviewBar editMode={editMode} resetEditMode={resetEditMode} />
            }
        </>
    )
}
type EditBarProps = {
    editMode: FormRuleEditMode
    resetSelected?: () => void
    resetEditMode: () => void
    handleFormAssemblySteps?: (type: string | undefined) => void
}
const EditBar = ({ editMode, resetEditMode, resetSelected, handleFormAssemblySteps }: EditBarProps) => {

    return <>
        {

            <div className={contentStyles.formRuleHeader}>
                <div className={contentStyles.content}>
                    <strong>{editMode.rule?.ruleName}</strong>.
                    Select all tags that you want to use in your Form Assembly Rule&nbsp;
                            {editMode.type === "RULES_ACTIONS" ?
                        <strong>Actions</strong> :
                        editMode.type === "RULES_CONDITION" ?
                            <strong>Condition </strong> : null}
                                    &nbsp;(Content Controls selected {editMode.contentControls.length}).&nbsp;
                             <Link onClick={resetSelected}>Reset selection</Link>
                </div>
                <div className={contentStyles.buttonContainer}>
                    <PrimaryButton className={`${contentStyles.button} ml-2`} onClick={() => handleFormAssemblySteps?.(editMode.type)}>
                        {editMode.type === "RULES_ACTIONS" ?
                            'Actions' :
                            editMode.type === "RULES_CONDITION" ?
                                'Conditions' : null}
                    </PrimaryButton>
                    <DefaultButton className={`${contentStyles.button} ml-2`} onClick={resetEditMode}>
                        Cancel
                        </DefaultButton>
                </div>
            </div>
        }
    </>
}
const PreviewBar = ({ editMode, resetEditMode }: EditBarProps) => {
    return <div className={contentStyles.formRuleHeader}>
        <div className={contentStyles.content}>

            <IconButton iconProps={CheckboxFill} disabled={true} styles={iconConditionIndicator} />
            Conditions ({editMode.rule?.conditions.length} rules)

            <IconButton iconProps={CheckboxFill} disabled={true} styles={iconActionsIndicator} />
            Actions ({editMode.rule?.actions.length} expresions)
        </div>
        <div className={contentStyles.buttonContainer}>
            <DefaultButton className={`${contentStyles.button} ml-2`} onClick={resetEditMode}>
                Close
            </DefaultButton>
        </div>
    </div>
}
const CheckboxFill: IIconProps = { iconName: 'CheckboxFill' };
const iconActionsIndicator: IButtonStyles = {
    rootDisabled: {
        backgroundColor: 'transparent',
    },
    iconDisabled: {
        color: '#ffd3d3'
    }
};
const iconConditionIndicator: IButtonStyles = {
    rootDisabled: {
        backgroundColor: 'transparent',
    },
    iconDisabled: {
        color: '#c9efaf'
    }
};
const contentStyles = mergeStyleSets({

    content: {
        padding: "0 4px"
    },
    buttonContainer: {
        display: 'flex'
    },
    button: {
        padding: "2px"
    },
    formRuleHeader: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid rgb(64, 102, 146)",
        padding: "8px",
        alignItems: "center",
        margin: "0 4px"
    },
    iconActions: { color: '#ffd3d3' },
    iconCondition: { color: '#c9efaf' },
}
)