import { IButtonStyles, IContextualMenuItem, mergeStyleSets, IContextualMenuProps, IIconProps, IconButton, Label, TextField, FontWeights, getTheme, Button, DefaultButton, PrimaryButton, Pivot, PivotItem, Link, ActionButton } from '@fluentui/react';
import React, { useState } from 'react'
import { ILayout } from '../CustomTemplate';
import CustomDialog from '../../../share/CustomDialog';
import ExpressionBuilder from '../ExpressionBuilder';
import { useConstCallback } from '@fluentui/react-hooks';
import WizardService, { OperationsManualTagType } from '../../../services/wizard';
import StatusMessage, { StatusEnum } from '../../../share/StatusMessage';
import { DEFAULT_EDIT_MODE, FormAssemblyActions, FormRuleEditMode, IFormAssemblyRule } from '../../../interfaces/IFormAssembly';
import { useModal } from '../../../hooks/useModal';
import { Sizes } from '../../../share/CustomModal';
import FormAssemblyModal from './formAssembly/FormAssemblyModal';
import { IStatusMessage } from '../../../interfaces/IApp';
import { useFormAssembly } from './hooks/useFormAssembly';
import { useBranding } from '../../../hooks/useBranding';

const theme = getTheme();

const saveIcon: IIconProps = { iconName: "Save" };
const previewIcon: IIconProps = { iconName: "PreviewLink" };
const pageIcon: IIconProps = { iconName: "PageAdd" };
const pageRIcon: IIconProps = { iconName: "PageRemove" };
const ExpIcon: IIconProps = { iconName: "EngineeringGroup" };
const PlusIcon: IIconProps = { iconName: "AppIconDefaultAdd" };
const Source: IIconProps = { iconName: "Source" };
const RedEye: IIconProps = { iconName: "RedEye" };

type ToolBoxProps = {
    templateId: any
    openPreview: any
    templateName: any
    siteSelected: any
    items: ILayout[];
    targetButtonT: any
    onAddPage: any
    setItemDragged: Function;
    onTakeItem: any;
    unexpectedError: boolean
    saveLayoutOnStateManagement: any
    onRemovePage: any
    pageCount: any
    pageSelected: any
    getDefaultLayout: any
    setToolBoxItems: React.Dispatch<React.SetStateAction<ILayout[]>>
    saveToolboxItems: any
    setOperationsManualTags: React.Dispatch<React.SetStateAction<OperationsManualTagType[]>>
    operationsManualTags: OperationsManualTagType[]
    layouts: ILayout[]
};

export default function ToolBox({
    templateId, templateName, siteSelected, items, openPreview, targetButtonT, onAddPage,
    setItemDragged, onTakeItem, unexpectedError, saveLayoutOnStateManagement,
    onRemovePage, pageCount, pageSelected, getDefaultLayout, setToolBoxItems,
    saveToolboxItems, setOperationsManualTags, operationsManualTags, layouts }: ToolBoxProps) {

    const { handleFormAssembly, onEditModeChange, formRules } = useFormAssembly()
    const [modalExpBuilder, setModalExpBuilder] = useState(false);

    const [manualTagName, setManualTagName] = useState("");
    const [isOpenManualTag, setIsOpenManualTag] = useState(false);
    const [manualErrorMessage, setManualErrorMessage] = useState("");
    const formRuleModal = useModal()

    const toggleExpBuilder = useConstCallback((open: boolean) => {
        if (!open) {
            // clearFields()
        }

        setModalExpBuilder(open);
    });

    const onChangeManualTag = (event: any) => {
        setManualTagName(event.target.value);
    };

    const openManualTag = () => {
        setIsOpenManualTag(true);
        setManualErrorMessage("");
        setManualTagName("");
    };

    const addTag = (tagname: string) => {
        const tempControl: ILayout = getDefaultLayout(tagname, tagname, false);
        setManualErrorMessage("");
        setToolBoxItems((items) => {
            const toolboxItems = [
                ...items,
                { ...tempControl, manualTag: true },
            ];
            saveToolboxItems(toolboxItems);
            return toolboxItems;
        });

        setOperationsManualTags((prevs) => {
            return [...prevs, { id: manualTagName, operation: "add" }];
        });
    }

    const deleteManualTag = (tagname: string) => {
        setToolBoxItems((items) => {
            const filtered = items.filter((i) => i.Id !== tagname);
            saveToolboxItems([...filtered]);
            setOperationsManualTags((prevs) => {
                const isInOperation = prevs.find((o) => o.id === tagname);
                if (isInOperation) return prevs.filter((i) => i.id !== tagname);
                return [...prevs, { id: tagname, operation: "delete" }];
            });
            return [...filtered];
        });
    };

    const saveManualTag = () => {
        const _manualTagName = manualTagName.trim()
        const isInOperations = operationsManualTags.find(
            (item) => item.id === _manualTagName && item.operation === "delete"
        );
        const isInLayouts = layouts.find(
            (item) => item.Id.toLowerCase() === _manualTagName.toLowerCase()
        );
        if (isInOperations && !isInLayouts) {
            addTag(_manualTagName);
            setOperationsManualTags((prevs) => {
                return prevs.filter(
                    (i) => i.id.toLowerCase() !== _manualTagName.toLowerCase()
                );
            });
            setIsOpenManualTag(false);
            return;
        }

        if (!isInLayouts) {
            WizardService.validateContentControl({
                TemplateId: Number(templateId),
                TagNames: [_manualTagName],
            }).then(({ data }: any) => {
                if (data.Success) {
                    addTag(_manualTagName);
                    setIsOpenManualTag(false);
                } else {
                    setManualErrorMessage(data.ExceptionMessage);
                }
            });
        } else {
            setManualErrorMessage(
                `Content control(s) '${_manualTagName}' already exists for this template`
            );
        }
    }

    return (
        <>

            {!unexpectedError ? (
                <>
                    <div className="ms-Grid-row">
                        <div>

                            <div className={contentStyles.buttonBar}>
                                <div className={contentStyles.buttonBarSection}>
                                    <IconButton
                                        id="btnSaveWizard"
                                        styles={iconBarButtonStyles}
                                        iconProps={saveIcon}
                                        ariaLabel="Save"
                                        onClick={saveLayoutOnStateManagement}
                                        title="Save form wizard layout and configuration."
                                    />
                                    <IconButton
                                        id="btnPreviewWizard"
                                        styles={iconBarButtonStyles}
                                        iconProps={previewIcon}
                                        ariaLabel="Preview"
                                        onClick={openPreview}
                                        title="Preview form wizard layout."
                                    />
                                    <IconButton
                                        id="btnNewPageWizard"
                                        styles={iconBarButtonStyles}
                                        iconProps={pageIcon}
                                        ariaLabel="New Page"
                                        onClick={onAddPage}
                                        title="Select additional pages to spread or organize controls across form wizard. "
                                    />
                                    <IconButton
                                        id="btnRemovePageWizard"
                                        styles={iconBarButtonStyles}
                                        iconProps={pageRIcon}
                                        ariaLabel="Remove Page"
                                        onClick={onRemovePage}
                                        title="Remove page from web form wizard."
                                        disabled={pageCount === 1 || pageSelected === 1 ? true : false}
                                    />
                                </div>
                                <div className={contentStyles.buttonBarSection}>
                                    <IconButton
                                        id="btnExpressionBuilder"
                                        styles={iconBarButtonStyles}
                                        iconProps={ExpIcon}
                                        ariaLabel="Expression Builder"
                                        onClick={() => {
                                            toggleExpBuilder(true);
                                        }}
                                        title="Open expression builder, performs basic math, date and string concatenation operations."
                                    />
                                    <IconButton
                                        id="btnManualTag"
                                        styles={iconBarButtonStyles}
                                        iconProps={PlusIcon}
                                        ariaLabel="Manual Tag"
                                        onClick={openManualTag}
                                        title="Add manual tag."
                                    />
                                    {/*<ActionButton styles={iconBarButtonStyles} iconProps={Source} onClick={formRuleModal.open} ariaLabel="Form Assembly Rule" title="(Beta) Add form assembly rule.">
                                        <span className={contentStyles.formAssemblyBeta}>(Beta)</span>
                                    </ActionButton>*/}
                                    {/* <IconButton
                                        id="btnFormAssembly"
                                        styles={iconBarButtonStyles}
                                        iconProps={Source}
                                        ariaLabel="Form Assembly Rule"
                                        
                                        
                                    /> */}
                                    <div style={{ width: '30px' }}></div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div
                        className="ms-Grid-row"
                        ref={targetButtonT}
                        style={{ height: "60vh", }}
                    >
                        <Pivot style={{ maxWidth: '240px' }}>
                            <PivotItem  headerText="Content Controls" className={contentStyles.pivotContainer} style={{width: "240px"}}>
                                <div
                                    className="scrollVisible"
                                    style={{
                                        overflow: "auto", maxWidth: "100%",
                                        overflowX: "hidden", height: "53vh",
                                    }}
                                >
                                    {items !== undefined ? (
                                        <div className="toolbox">
                                            <Label></Label>
                                            <div className="toolbox__items">
                                                {items.map((item: ILayout) => (
                                                    <ToolBoxItem
                                                        key={item.Id}
                                                        item={item}
                                                        onTakeItem={onTakeItem}
                                                        setItemDragged={setItemDragged}
                                                        deleteTag={deleteManualTag}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                            <div>No content controls</div>
                                        )}
                                </div>
                            </PivotItem>
                            {/*<PivotItem headerText={"Form Rules"} className={contentStyles.pivotContainer}>*/}
                            {/*    <div*/}
                            {/*        className="scrollVisible"*/}
                            {/*        style={{*/}
                            {/*            overflow: "auto", maxWidth: "100%",*/}
                            {/*            overflowX: "hidden", height: "53vh",*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {formRules !== undefined ? (*/}
                            {/*            <div className="toolbox">*/}
                            {/*                <Label></Label>*/}
                            {/*                <div className="toolbox__items">*/}
                            {/*                    {formRules.map((item) => (*/}
                            {/*                        <FormRuleItem layouts={layouts}*/}
                            {/*                            key={item.ruleName}*/}
                            {/*                            item={item} handleFormAssembly={handleFormAssembly}*/}
                            {/*                            onEditModeChange={onEditModeChange}*/}
                            {/*                        />*/}
                            {/*                    ))}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        ) : (*/}
                            {/*                <div>No content controls</div>*/}
                            {/*            )}*/}
                            {/*    </div>*/}
                            {/*</PivotItem>*/}
                        </Pivot>

                    </div>
                </>
            ) : null}
            <ExpressionBuilder
                modalExpBuilder={modalExpBuilder}
                toggleExpBuilder={toggleExpBuilder}
                templateId={templateId}
                layouts={layouts}
                templateName={templateName}
                siteSelected={siteSelected}
            />
            <CustomDialog
                title={"Manual tag name."}
                titleId={"ManualTag"}
                isDisabled={manualTagName.trim().length === 0}
                close={() => {
                    setIsOpenManualTag(false);
                }}
                isOpen={isOpenManualTag}
                actionText={"Add"}
                confirm={saveManualTag}
            >
                <>
                    {manualErrorMessage !== "" ? (
                        <StatusMessage
                            setShowMessage={() => setManualErrorMessage("")}
                            status={StatusEnum.Error}
                        >
                            {manualErrorMessage}
                        </StatusMessage>
                    ) : (
                            <></>
                        )}
                    <TextField
                        label="Name"
                        required
                        maxLength={50}
                        onChange={onChangeManualTag}
                        placeholder="Manual tag name."
                        id="manualTagName"
                        title=""
                        value={manualTagName}
                    />
                </>
            </CustomDialog>
            <formRuleModal.Modal title="(Beta) Form Assembly" titleId="formAsemblyRuleActionsModal" size={Sizes.xs}>
                <FormAssemblyModal close={formRuleModal.close} handleFormAssembly={handleFormAssembly} />
            </formRuleModal.Modal>
        </>
    )
}
type ToolBoxItemProps = {
    item: ILayout;
    setItemDragged: Function;
    onTakeItem: any;
    deleteTag: Function;
};

function ToolBoxItem({
    item,
    setItemDragged,
    onTakeItem,
    deleteTag,
}: ToolBoxItemProps) {
    const { branding } = useBranding()
    const menuItems: IContextualMenuItem[] = [
        {
            key: "delete",
            text: "Delete",
            title: "Delete",
            onClick: () => {
                deleteTag(item.Id);
            },
        },
    ];
    const menuPropsItem: IContextualMenuProps = {
        items: menuItems,
    };
    return (
        <>
            <div
                className="toolbox__items__item divHover divToolBoxItem"
                style={{ backgroundColor: branding.theme.palette.themeDarker }}
                onMouseDown={() => {
                    setItemDragged(item.Id);
                }}
                id={item.Id}
                draggable={true}
                unselectable="on"
                onDragStart={(e) =>
                    e.dataTransfer.setData("text/plain", "hello")
                }
            >
                <span>{item.Label}</span>
                {item.manualTag ? (
                    <IconButton
                        menuIconProps={{
                            iconName: "MoreVertical",
                            style: { fontSize: 20 },
                        }}
                        role="button"
                        title="Click to select from available actions."
                        data={item}
                        aria-haspopup
                        aria-label="Show actions"
                        styles={iconTagButtonStyles}
                        menuProps={menuPropsItem}
                    />
                ) : null}
            </div>
        </>
    );
}
type FormRuleItemProps = {
    item: IFormAssemblyRule;
    handleFormAssembly: (option: FormAssemblyActions, rule: IFormAssemblyRule) => IStatusMessage
    onEditModeChange: (editMode: FormRuleEditMode) => void
    layouts: ILayout[]
};
function FormRuleItem<T>({
    item,
    layouts,
    handleFormAssembly,
    onEditModeChange
}: FormRuleItemProps) {
    const { setEditMode, resetEditMode } = useFormAssembly()
    const { branding } = useBranding()
    const menuItems: IContextualMenuItem[] = [
        {
            key: "show",
            text: "Preview",
            title: "Preview",
            onClick: async () => {

                await setEditMode((prev) => {
                    return {
                        ...prev,
                        ...DEFAULT_EDIT_MODE
                    }
                })
                setEditMode((prev) => {
                    return {
                        ...prev,
                        type: 'RULE_PREVIEW', contentControls: [], rule: item, showBar: false
                    }
                })
            },
        },
        {
            key: "delete",
            text: "Delete",
            title: "Delete",
            onClick: () => {
                handleFormAssembly('DELETE', item)
            },
        },
    ];
    const menuPropsItem: IContextualMenuProps = {
        items: menuItems,
    };

    return (
        <>

            <div
                className={contentStyles.formRuleItem}
                id={item.ruleName}
            >
                <div className={contentStyles.formRuleHeader} style={{ backgroundColor: branding.theme.palette.themeDarker }}>
                    <span>{item.ruleName}</span>
                    <span>
                        <IconButton
                            menuIconProps={{
                                iconName: "MoreVertical",
                                style: { fontSize: 20 },
                            }}
                            role="button"
                            title="Click to select from available actions."
                            data={item}
                            aria-haspopup
                            aria-label="Show actions"
                            styles={iconTagButtonStyles}
                            menuProps={menuPropsItem}
                        />
                        {/* <IconButton
                            id="btnFormRuleAssembly"
                            styles={iconRedEyeButtonStyles}
                            iconProps={RedEye}
                            ariaLabel="Form Assembly Rule"
                            onClick={() => { console.log('View', item) }}
                            title="Form Assembly Rule"
                        /> */}
                    </span>
                </div>
                <div className={contentStyles.formRuleBody}>
                    {item.adminOnly && <div>{item.adminOnly ? 'Only for Admininstrators' : ''}</div>}
                    <div className={contentStyles.formRuleBodyBtns}>
                        <Link onClick={() => { onEditModeChange({ type: "RULES_CONDITION", contentControls: [], rule: item, showBar: true }) }}>
                            <strong>Condition:</strong> {item.conditions.length}
                        </Link>
                        <Link onClick={() => { onEditModeChange({ type: "RULES_ACTIONS", contentControls: [], rule: item, showBar: true }) }}>
                            <strong>Actions:</strong> {item.actions.length}
                        </Link>
                    </div>
                </div>

                {/* <IconButton
                    menuIconProps={{
                        iconName: "MoreVertical",
                        style: { fontSize: 20 },
                    }}
                    role="button"
                    title="Click to select from available actions."
                    data={item}
                    aria-haspopup
                    aria-label="Show actions"
                    styles={iconTagButtonStyles}
                    menuProps={menuPropsItem}
                /> */}
            </div>
        </>
    );
}
const iconTagButtonStyles: Partial<IButtonStyles> = {
    root: { float: "right", height: "inherit", background: "transparent" },
    rootHovered: { background: "transparent" },
    rootPressed: { background: "transparent" },
    icon: { color: "#fff" },
    menuIcon: { color: "#fff" },
};
const iconBarButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginTop: "4px",
        marginRight: "2px",
        width: "30px",
        height: "30px",
        selectors: {
            ":hover": {
                cursor: "pointer",
                backgroundColor: "rgb(243, 242, 241)"
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    icon: {
        color: theme.palette.neutralPrimary,
    }
};
const iconRedEyeButtonStyles = {
    root: {
        color: "#ffffff",
        width: "22px",
        height: "22px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    rootHovered: {
        color: "#ffffff",
        backgroundColor: "#6d94c1"
    },
};
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },
    correctHeader: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: "center",
            fontWeight: FontWeights.semibold,
        },
    ],
    header: [
        theme.fonts.xLarge,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],

    body: {
        width: "95%",
        height: "75vh",
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    bodyMail: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        minHeight: 500,
    },
    containerMail: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "50vw",
    },
    pivotContainer: {
        padding: "8px"
    },
    containerSaveModal: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "30vw",
        height: "20vh",
    },
    panelHeaderSubtitle: {
        fontFamily: "Calibri",
        fontWeight: "bold",
        fontSize: 18,
    },
    callout: {
        width: 320,
        maxWidth: "90%",
        padding: "16px 8px",
    },
    labelHelp: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    msLabel: {
        fontWeight: "600",
    },
    buttonBar: {
        display: "flex",
        flexDirection: "column"
    },
    buttonBarSection: {
        display: "flex",
        justifyContent: "space-around",
    },
    formRuleItem: {
        border: "1px solid rgb(64, 102, 146)",
        display: "flex",
        flexDirection: "column",
        marginBottom: '5px'
    },
    formRuleHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px 2px",
        color: "#fff"
    },
    formRuleBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "4px 2px"
    },
    formRuleBodyBtns: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    formAssemblyBeta: {
        padding: "5.5px 2px",
        color: '#a4262c',
    }
});