import React, { useState, useCallback, HTMLAttributes, useContext } from 'react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useId } from '@uifabric/react-hooks';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { Modal } from '@fluentui/react/lib/Modal';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import '../../Custom.css';
import { Worker, Viewer, SpecialZoomLevel, LocalizationMap, LocalizationContext } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { isMobile } from 'react-device-detect';
import { IDocument } from '../../App';
import Confirmation from '../Settings/Confirmation';
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { IconButton, IButtonStyles, DefaultButton, PrimaryButton, IButtonProps } from '@fluentui/react/lib/Button';
import Helper, { ITemplateLayoutRequest, IDeleteDocumentRequest, IDocumentVersionRequest, IDocumentRequest, ICheckinCheckoutDocumentOnlyRequest, IDeleteDocumentOnlySignature, ICreateDocumentRequest, IFormControl, IDocumentOnlyAudienceDetailRequest } from '../../Helpers/Helper';
import { decode } from 'base64-arraybuffer'
import { ReactNotificationOptions, store } from 'react-notifications-component';
import { MessageBar, IMessageBarStyles, Panel, PanelType } from '@fluentui/react';

import { useTranslation } from "react-i18next";

import en_ from './locales/en_US.json';
import es_ from './locales/es_ES.json';
import { useBranding } from '../../hooks/useBranding';


const toolbarPluginInstance = toolbarPlugin();
const thumbnailPluginInstance = thumbnailPlugin();
const { Toolbar } = toolbarPluginInstance;
const { Thumbnails } = thumbnailPluginInstance;
const FileDownload = require("js-file-download");

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    
    bodyMail: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        minHeight: 500,
    },
    containerSaveModal: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: isMobile ? null : '30vw',
        height: '20vh',
    },
    body: {
        width: '80vw',
        height: '75vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        //overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
    bodyPanel: {
        width: '80vw',
        height: '75vh',
        flex: '4 4 auto',
        padding: '0 24px 24px 0px',
        //overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
            ':disabled': {
                backgroundColor: theme.palette.white
            },
        },
    },
    
};
const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};
const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5"
    }
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac"
    }
};



const stackTokens = { childrenGap: 10 };

const iconButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    
};



const cancelIcon: IIconProps = { iconName: 'Cancel' };

const getRecordDescription = (title: string, description: string) => {

    var descriptionBuffer = " (" + description + ")";

    if (description === null || description === undefined || description.trim().length == 0) {
        descriptionBuffer = "";
    }

    var value;
    isMobile ? value = title : value = title + descriptionBuffer;
    return value;
}



function Preview(props: any) {
    const titleId = useId(props.modalId);
    const [downloadDocX, setDownloadDocX] = useState(false)
    const [docSelected, setDocSelected] = useState<IDocument>()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [downloadType, setDownloadType] = useState("pdf")
    const [allowDocX, setAllowDocX] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState("");
    const { t } = useTranslation(['common', 'preview']);

    const localizationContextEs = es_ as any as LocalizationMap;
    const localizationContextEn = en_ as any as LocalizationMap;
    const {branding} = useBranding()

    const isLink = ():boolean => {
        const location = window.location.toString()
        if( location.toLowerCase().includes("documentlink") ||
            location.toLowerCase().includes("campaignlink") ||
            location.toLowerCase().includes("link")) {            
            return true
        }
        else {
            return false
        }
    }    
    
    const headerStyle = mergeStyleSets({
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                //borderTop: `4px solid ${branding.backgroundColor}`,
                borderTop: `4px solid ${ isLink()? branding.backgroundColor: branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
    })

    const panelHeader: HTMLAttributes<HTMLDivElement> = {
        style: {
            fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
            fontWeight: "bolder",
            color: branding.theme.palette.themePrimary,
            fontSize: 20
        }
    }
    
    const classNames = mergeStyleSets({
        hideMenu: {
            display: 'none'
        },
        showMenu: {
            display: 'inline'
        },
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            minHeight: '16px',
            minWidth: '16px',
            maxHeight: '16px',
            maxWidth: '16px',
        },
        controlWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        exampleToggle: {
            display: 'inline-block',
            marginBottom: '10px',
            marginRight: '30px',
        },
        selectionDetails: {
            marginBottom: '20px',
        },
        bodyNote: {
            width: isMobile ? null : '25vw',
            height: '20vh',
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0px 24px',
            },
        ],
    });

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)

    }

    function downloadDocument() {

        var isDocX = downloadType !== 'pdf'
        onItemDownload(docSelected!, isDocX)
    }



    const onItemDownload = (item: IDocument, isDocx: boolean) => {
        setShowConfirmationModal(false)
        var id = props.documentId

        if (id === '-1')
            id = props.Id

        var username = ""
        var auxUser = props.username ?? Helper.getUsername()
        if (auxUser !== null && auxUser !== undefined)
            username = auxUser
        else
            username = props.authUser

        const download = async () => {

            if (id === null || id === undefined) {

                var link = document.createElement("a")
                link.href = `/Download/${props.URLPath}?mimetype=${props.mimeType}`
                link.download = props.modalTitle
                link.click();
                setShowSuccessMessage(true)
                setMessage(t('messages.success', {ns: 'preview'}))
                setTimeout(function () {
                    setShowSuccessMessage(false)
                }, 5000);
            } else {

                const req: IDocumentRequest = {
                    UserName: username,
                    SessionKey: Helper.getSessionKey(),
                    AccountId: Helper.getDefaultAccount(),
                    DocumentId: "-1",
                    Id: id?.toString(),
                    IsPreview: id !== null && id !== undefined ?  false : true,
                    IsDownload: true,
                    IsDocx: isDocx
                }
                var res = await Helper.getDocumentDownload(req)

                if (res.Success === undefined || !res.Success) {

                    const name = isDocx ? `${res.Description}.docx` : `${res.Description}.pdf`
                    try {
                        const decoded = decode(res.Content);
                        const file = new Blob(
                            [decoded], {type: res.MimeType});
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(file);
                        a.download = name
                        a.click();
                        setShowSuccessMessage(true)
                        setMessage(t('messages.success', {ns: 'preview'}))
                        setTimeout(function () {
                            setShowSuccessMessage(false)
                        }, 5000);
                    } catch (error) {
                        setShowErrorMessage(true)
                        setMessage(t('messages.error', {ns: 'preview', Error: error}))
                        setTimeout(function () {
                            setShowErrorMessage(false)
                        }, 5000);
                    }
                }
            }
        }

        const downloadWithSignatures = async () => {


            var data = await Helper.GetDocumentOnlySignatureWithContentByDocAndSignature(props.documentId.toString(), props.Id)
            var res = JSON.parse(data.response)
            try {
                const decoded = decode(res.Content);
                const file = new Blob(
                    [decoded], { type: "application/pdf" });
                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(file);
                console.log(res)
                a.download = res.DocumentName.trim() + ".pdf";// res.FIleExtn.trim();
                a.click();
                setShowSuccessMessage(true)
                setMessage(t('messages.success', { ns: 'preview' }))
                setTimeout(function () {
                    setShowSuccessMessage(false)
                }, 5000);
            } catch (error) {
                setShowErrorMessage(true)
                setMessage(t('message.error', { ns: 'common', Error: error }))
                setTimeout(function () {
                    setShowErrorMessage(false)
                }, 5000);
            }
        }

        if (props.signatures !== undefined && props.signatures) downloadWithSignatures()
        else download()
    }

    const getAllowDocx = () => {
        var value = false
        if (props.allowDocX) { return true }
        return value
    }

    const dismissPanel = () => {
        //setIsPanelOpen(false)
        props.hideModal()
    }

    
    return (
        <>

            {!isMobile ? <Modal
                isOpen={props.showModal}
                isBlocking={true}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack>
                                <div className={headerStyle.header}>
                                    <span id={titleId} dangerouslySetInnerHTML={{ __html: getRecordDescription(props.modalTitle, props.documentDescription) }}></span>

                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}

                                        ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                        onClick={props.hideModal}
                                        title={t('Common.Button.Close', { ns: 'common' })}
                                    />

                                </div>
                                <Separator />
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={contentStyles.body}>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                    </div>
                                </div>

                                {props.layoutLoaded ?
                                    <div className="ms-Grid-col ms-sm12 ms-lg12">

                                        <LocalizationContext.Provider value={Helper.getCulture() === "es" ? localizationContextEs : localizationContextEn}>

                                            <Worker workerUrl="../pdf.worker.min.js">


                                                <div style={{ height: '100%' }}>
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            padding: '4px',
                                                        }}
                                                    >

                                                        <Toolbar>
                                                            {
                                                                (props: ToolbarSlot) => {
                                                                    const {
                                                                        CurrentPageInput, GoToNextPage, GoToPreviousPage,
                                                                        NumberOfPages, ShowSearchPopover, Zoom, ZoomIn,
                                                                        ZoomOut,
                                                                    } = props;
                                                                    return (
                                                                        <>
                                                                            <div style={{ padding: '0px 2px' }}>
                                                                                <ShowSearchPopover />
                                                                            </div>
                                                                            <div className="ms-hiddenMdDown" style={{ padding: '0px 2px' }}>
                                                                                <ZoomOut />
                                                                            </div>
                                                                            <div style={{ padding: '0px 2px' }}>
                                                                                <Zoom />
                                                                            </div>
                                                                            <div className="ms-hiddenMdDown" style={{ padding: '0px 2px' }}>
                                                                                <ZoomIn />
                                                                            </div>
                                                                            <div style={{ padding: '0px 2px', marginBottom: '4px', cursor: "pointer" }}>
                                                                                {isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? null :
                                                                                    <img style={{ cursor: 'pointer', display: 'block', textAlign: 'right' }}
                                                                                        onClick={() => {
                                                                                            setDownloadDocX(false)
                                                                                            setDocSelected(docSelected)
                                                                                            setShowConfirmationModal(true)
                                                                                            setDownloadType("pdf")

                                                                                        }} title={t('title.downloadPDF', { ns: 'preview' })}
                                                                                        src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg"} className={classNames.fileIconImg} alt={t('title.downloadPDF', { ns: 'preview' })} />
                                                                                }
                                                                            </div>
                                                                            {getAllowDocx() ?
                                                                                <div style={{ padding: '0px 2px', marginBottom: '4px', cursor: "pointer" }}>
                                                                                    {isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? null :
                                                                                        <img style={{ cursor: 'pointer', display: 'block', textAlign: 'right' }}
                                                                                            onClick={() => {
                                                                                                setDownloadDocX(false)
                                                                                                setDocSelected(docSelected)
                                                                                                setShowConfirmationModal(true)
                                                                                                setDownloadType("docx")

                                                                                            }} title={t('title.downloadDOCX', { ns: 'preview' })}
                                                                                            src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/docx.svg"} className={classNames.fileIconImg} alt={t('title.downloadDOCX', { ns: 'preview' })} />
                                                                                    }
                                                                                </div>
                                                                                : null}
                                                                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                                                                <GoToPreviousPage />
                                                                            </div>
                                                                            <div style={{ padding: '0px 2px' }}>
                                                                                <CurrentPageInput /> / <NumberOfPages />
                                                                            </div>
                                                                            <div style={{ padding: '0px 2px' }}>
                                                                                <GoToNextPage />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                        </Toolbar>

                                                    </div>
                                                    <div
                                                        style={{
                                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                                            display: 'flex',
                                                            height: '72vh',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                            }}
                                                        >

                                                            <Viewer
                                                                fileUrl={`/Download/${props.URLPath}?mimetype=${props.mimeType}`}
                                                                defaultScale={SpecialZoomLevel.PageWidth}
                                                                plugins={[
                                                                    toolbarPluginInstance,
                                                                    thumbnailPluginInstance,
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </Worker>

                                        </LocalizationContext.Provider>

                                    </div>
                                    :
                                    <Stack tokens={stackTokens}>
                                        <label />
                                        <label />
                                        <label />
                                        <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
                :
                <Panel
                    headerText={getRecordDescription(props.modalTitle, props.documentDescription)}
                    headerTextProps={panelHeader}
                    isBlocking={false}
                    isOpen={props.showModal}
                    onDismiss={dismissPanel}
                    closeButtonAriaLabel={t('Common.Button.Close', { ns: 'common' })}
                    type={PanelType.smallFluid}
                >
                    <div className="ms-Grid" dir="ltr">
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.bodyPanel}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                            {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}
                                        </div>
                                    </div>
                                    {props.layoutLoaded ?
                                        <div className="ms-Grid-col ms-sm12 ms-lg12">


                                            <LocalizationContext.Provider value={Helper.getCulture() === "es" ? localizationContextEs : localizationContextEn}>

                                                <Worker workerUrl="../pdf.worker.min.js">

                                                    <div style={{ height: '100%' }}>
                                                        <div
                                                            style={{
                                                                alignItems: 'center',
                                                                backgroundColor: '#eeeeee',
                                                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                                display: 'flex',
                                                                padding: '4px',
                                                            }}
                                                        >

                                                            <Toolbar>
                                                                {
                                                                    (props: ToolbarSlot) => {
                                                                        const {
                                                                            CurrentPageInput, GoToNextPage, GoToPreviousPage,

                                                                            NumberOfPages, ShowSearchPopover, Zoom, ZoomIn,
                                                                            ZoomOut,
                                                                        } = props;
                                                                        return (
                                                                            <>
                                                                                <div style={{ padding: '0px 2px' }}>
                                                                                    <ShowSearchPopover />
                                                                                </div>

                                                                                <div className="ms-hiddenMdDown" style={{ padding: '0px 2px' }}>
                                                                                    <ZoomOut />
                                                                                </div>
                                                                                <div style={{ padding: '0px 2px' }}>
                                                                                    <Zoom />
                                                                                </div>

                                                                                <div className="ms-hiddenMdDown" style={{ padding: '0px 2px' }}>
                                                                                    <ZoomIn />
                                                                                </div>
                                                                                <div style={{ padding: '0px 2px', marginBottom: '4px', cursor: "pointer" }}>
                                                                                    {isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? null :
                                                                                        <img style={{ cursor: 'pointer', display: 'block', textAlign: 'right' }}
                                                                                            onClick={() => {
                                                                                                setDownloadDocX(false)
                                                                                                setDocSelected(docSelected)
                                                                                                setShowConfirmationModal(true)
                                                                                                setDownloadType("pdf")

                                                                                            }} title={t('title.downloadPDF', { ns: 'preview' })}
                                                                                            src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg"} className={classNames.fileIconImg} alt={t('title.downloadPDF', { ns: 'preview' })} />
                                                                                    }
                                                                                </div>
                                                                                {getAllowDocx() ?
                                                                                    <div style={{ padding: '0px 2px', marginBottom: '4px', cursor: "pointer" }}>
                                                                                        {isMobile && sessionStorage.getItem("entityId") !== "Not In Teams" ? null :
                                                                                            <img style={{ cursor: 'pointer', display: 'block', textAlign: 'right' }}
                                                                                                onClick={() => {
                                                                                                    setDownloadDocX(false)
                                                                                                    setDocSelected(docSelected)
                                                                                                    setShowConfirmationModal(true)
                                                                                                    setDownloadType("docx")

                                                                                                }} title={t('title.downloadDOCX', { ns: 'preview' })}
                                                                                                src={"https://static2.sharepointonline.com/files/fabric/assets/item-types/16/docx.svg"} className={classNames.fileIconImg} alt={t('title.downloadDOCX', { ns: 'preview' })} />
                                                                                        }
                                                                                    </div>
                                                                                    : null}
                                                                                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                                                                    <GoToPreviousPage />
                                                                                </div>
                                                                                <div style={{ padding: '0px 2px' }}>
                                                                                    <CurrentPageInput /> / <NumberOfPages />
                                                                                </div>
                                                                                <div style={{ padding: '0px 2px' }}>
                                                                                    <GoToNextPage />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                }
                                                            </Toolbar>

                                                        </div>
                                                        <div
                                                            style={{
                                                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                                                display: 'flex',
                                                                height: '72vh',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: 1,
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                <Viewer
                                                                    fileUrl={`/Download/${props.URLPath}?mimetype=${props.mimeType}`}
                                                                    defaultScale={SpecialZoomLevel.PageWidth}
                                                                    plugins={[
                                                                        toolbarPluginInstance,
                                                                        thumbnailPluginInstance,
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Worker>

                                            </LocalizationContext.Provider>


                                        </div>
                                        :
                                        <Stack tokens={stackTokens}>
                                            <label />
                                            <label />
                                            <label />
                                            <Spinner size={SpinnerSize.large} label={props.spinnerText} />
                                        </Stack>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            }

            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={headerStyle.header}>
                                <span id="Confirmation">{t('label.downloadConfirmation', { ns: 'preview' })}</span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t('Common.Button.Close', { ns: 'common' })}
                                    onClick={hideConfirmationModal}
                                    title={t('Common.Button.Close', { ns: 'common' })}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div id="messageDownloadDocument" className={contentStyles.bodyMail}>
                                {t('text.downloadConfirmation', { ns: 'preview' })}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ bottom: "10px", right: "12px", position: "absolute" }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton onClick={downloadDocument} text={t('Common.Button.Yes', { ns: 'common' })} title={t('title.yesButton', { ns: 'preview' })} />
                                <DefaultButton onClick={hideConfirmationModal} text={t('Common.Button.No', { ns: 'common' })} title={t('Common.Button.No', { ns: 'common' })} />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Preview